import { useNotification, useServiceHandlers } from '@/core'
import type {
  BaseServiceOptions,
  Pagination,
  TRecordAction,
} from '@/core/types'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import {
  type TCustomer,
  customerSalesHandlers,
  customersServiceHandlers,
  serviceConfig,
  TCustomers,
} from '../../..'

const { model, customers } = serviceConfig

export const useCustomerQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, enableQuery, filters, modelId } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TCustomer>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const deleteRecord = useMutation<void, AxiosError, TCustomer>(
    (payload) => customersServiceHandlers.deleteRecord(payload),
    {
      onSuccess: (_, sale) => serviceHandlers.deleteRecord(sale),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const editRecord = useMutation<TCustomer, AxiosError, TCustomer>(
    (payload: TCustomer) =>
      customersServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TCustomer, AxiosError, TCustomer>(
    (payload: TCustomer) => customersServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TCustomer, AxiosError, TRecordAction>(
    (payload: TRecordAction) => customersServiceHandlers.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TCustomers, AxiosError>(
    [customers.model, currentPage, filters?.loadRecords],
    () =>
      customerSalesHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
        modelId,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
    },
  )

  return {
    loadRecords,
    addRecord,
    editRecord,
    deleteRecord,
    actionRecord,
  }
}
