<script lang="ts">
import { defineComponent } from 'vue'
import Toast from 'primevue/toast'
import ConfirmDialog from 'primevue/confirmdialog'
import { useProvideTheme } from './core/config/theme/useTheme'
import { useProvideLang } from './core/config/i18n/useLang'
import { useNetwork } from '@vueuse/core'
import { DotNoConnectionOverlay } from '@/core/components'
import { useAppConfig } from './core'

export default defineComponent({
  components: {
    Toast,
    ConfirmDialog,
    DotNoConnectionOverlay,
  },
  setup() {
    useAppConfig()
    useProvideTheme()
    useProvideLang()

    const { isOnline } = useNetwork()

    return { isOnline }
  },
})
</script>

<template>
  <Toast />
  <ConfirmDialog />
  <DotNoConnectionOverlay v-if="!isOnline" />
  <router-view v-if="isOnline" />
</template>

<style lang="scss">
html {
  @apply scrollbar-hide;
  body {
    @apply w-full overflow-x-hidden bg-surface-50;
    @apply dark:bg-surface-800 dark:text-surface-0;
  }
}

label {
  @apply font-semibold text-sm;
}

input {
  @apply w-full;
}

.icon {
  &--sm {
    @apply text-base;
  }
  &--md {
    @apply text-lg;
  }
  &--lg {
    @apply text-xl;
  }
  &--xl {
    @apply text-2xl;
  }
}

.fade-enter-active,
.fade-leave-active {
  @apply transition-all duration-200 ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}

.p-dialog {
  @apply bg-surface-0 dark:bg-surface-900 shadow-lg rounded-md p-[21px] max-w-2xl;
  &-mask {
    @apply bg-black bg-opacity-20;
  }
  &-header {
    @apply flex justify-between items-center text-xl font-bold;
  }
  &-content {
    @apply py-[21px] flex gap-4 items-center;
  }
  &-footer {
    @apply flex justify-end gap-2;
    & button {
      @apply first:bg-transparent first:border-none first:text-surface-900 dark:first:text-surface-0 first:hover:bg-transparent;
    }
  }
}
</style>
