import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import { Pagination } from '@/core/types'
import { createInjectionState, toReactive } from '@vueuse/core'
import { computed, ref, toValue } from 'vue'
import type { TCustomerFile } from '../../../types'
import { useRoute } from 'vue-router'
import { TUploadBody, useFileQuery } from '../query/useFileQuery'

const [useProvideFileModule, useFileModule] = createInjectionState(() => {
  const rows = ref<number[]>([])
  const form = ref<TCustomerFile>({})
  const route = useRoute()
  const filters = ref<ToQueryStringFilters>({
    param: '',
    dates: {
      created_at: [],
    },
    order_by: 'created_at,desc',
    customer_id: [route.params?.id?.toString()],
  })

  const queueFilesUploading = ref<TUploadBody[]>([])

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['name', 'paternal_last_name', 'maternal_last_name'],
    dateSettings: {
      enableFormat: true,
    },
  })

  const modulePagination = usePagination()

  const isDetail = computed(() => route.name === 'customerDetail')

  const fileQuery = useFileQuery({
    currentPage: modulePagination.currentPage,
    modelId: computed(() => route.params?.id?.toString()),
    filters: {
      loadRecords: moduleQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: true,
      loadRecord: isDetail,
      loadCoOwnershipsRecords: isDetail,
    },
    handlers: {
      onLoadRecords: (pagination: Pagination) => {
        modulePagination.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at'],
  })

  const tableData = computed(() => fileQuery.loadRecords.data?.value?.data)

  const isLoading = computed(() => fileQuery.loadRecords.isLoading.value)

  const onUploadFile = async (payload: TUploadBody) => {
    await fileQuery.uploadFile.mutateAsync(payload)
    await fileQuery.loadRecords.refetch()
  }

  const onQueueUploading = (payload: TUploadBody) => {
    queueFilesUploading.value.push({
      file: payload.file,
      categoryId: toValue(payload.categoryId),
      customerId: toValue(payload.customerId),
    })
  }

  const onRemoveQueue = (payload: TUploadBody) => {
    queueFilesUploading.value = queueFilesUploading.value.filter(
      (queue) =>
        queue.categoryId !== payload.categoryId &&
        queue.customerId !== payload.customerId,
    )
  }

  const isUploading = (customerId: number, categoryId: number) => {
    return queueFilesUploading.value.find(
      (queue) =>
        queue.categoryId === categoryId && queue.customerId === customerId,
    )
  }

  return {
    tableData,
    filters,
    orderByOptions,
    rows,
    form,
    isLoading,
    modulePagination: toReactive(modulePagination),
    onUploadFile,
    onQueueUploading,
    onRemoveQueue,
    isUploading,
  }
})

export { useFileModule, useProvideFileModule }
