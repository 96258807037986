import { followUpTypesIds, useNotification } from '@/core'
import { useCatalogStore } from '@/core/store/catalogs'
import type {
  BaseServiceOptions,
  Pagination,
  TRequestCatalog,
} from '@/core/types'
import { TLots } from '@/modules/projects'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { MaybeRef } from 'vue'
import {
  catalogServiceHandlers,
  serviceConfig,
  TAdvisors,
  TCatalogs,
  TOrigins,
  TProject,
} from '../../..'

const { projects, advisors, statuses, campaigns, lots } = serviceConfig

const {
  advisorsServiceHandler,
  campaignsServiceHandler,
  statusesServiceHandler,
  projectsServiceHandler,
  categoriesCatalog,
  projectsLotsService,
} = catalogServiceHandlers

type BaseServiceOptionsOverride = {
  modelId?: Record<any, MaybeRef<number | string>>
}

export const useCatalogQuery = (
  options: Omit<BaseServiceOptions<Pagination>, 'modelId'> &
    BaseServiceOptionsOverride,
) => {
  const { enableQuery, filters, modelId } = options
  const { errorHttpNotification } = useNotification()
  const store = useCatalogStore()

  const requestCatalogs = useMutation<TCatalogs, AxiosError, TRequestCatalog>(
    (payload: TRequestCatalog) => categoriesCatalog.requestCatalog(payload),
    {
      onSuccess: (payload) => {
        if (payload.follow_up_types != undefined) {
          payload.follow_up_types = payload.follow_up_types?.filter(
            (item) => item.id !== followUpTypesIds.quotation_id,
          )
          store.setCatalogs(payload)
        } else {
          store.setCatalogs(payload)
        }
      },
      onError: (error) => errorHttpNotification(error),
    },
  )

  const advisorsCatalog = useQuery<TAdvisors, AxiosError>(
    [advisors.model, advisors.version, filters?.advisorsCatalog],
    () =>
      advisorsServiceHandler.loadCatalog({
        dynamicFilters: filters?.advisorsCatalog,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.advisorsCatalog,
      onError: (error) => errorHttpNotification(error),
    },
  )

  const campaignsCatalog = useQuery<TOrigins, AxiosError>(
    [campaigns.model, campaigns.version],
    () => campaignsServiceHandler.loadCatalog({}),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.campaignsCatalog,
      onError: (error) => errorHttpNotification(error),
    },
  )

  const statusesCatalog = useQuery<TOrigins, AxiosError>(
    [statuses.model, statuses.version, filters?.statusesCatalog],
    () =>
      statusesServiceHandler.loadCatalog({
        dynamicFilters: filters?.statusesCatalog,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.statusesCatalog,
      onError: (error) => errorHttpNotification(error),
    },
  )

  const projectsCatalog = useQuery<TProject[], AxiosError>(
    [projects.model, projects.version, filters?.projectsCatalog],
    () =>
      projectsServiceHandler.loadCatalog({
        dynamicFilters: filters?.projectsCatalog,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.projectsCatalog,
      onError: (error) => errorHttpNotification(error),
    },
  )

  const lotsCatalog = useQuery<TLots, AxiosError>(
    [lots.model, lots.version, filters?.lotsCatalog],
    () =>
      projectsLotsService.loadRecords({
        modelId: modelId?.projectId,
        dynamicFilters: filters?.lotsCatalog,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.lotsCatalog,
      onError: (error) => errorHttpNotification(error),
    },
  )

  return {
    advisorsCatalog,
    campaignsCatalog,
    statusesCatalog,
    projectsCatalog,
    requestCatalogs,
    lotsCatalog,
  }
}
