import { useNotification } from '@/core'
import type { BaseServiceOptions, Pagination } from '@/core/types'
import { useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import {
  appointmentServiceHandlers,
  serviceConfig,
  TAppointment,
} from '../../..'

const { appointments } = serviceConfig

export const useAppointmentQuery = (
  options: BaseServiceOptions<Pagination>,
) => {
  const { currentPage, enableQuery, filters } = options

  const { errorHttpNotification } = useNotification()

  const loadRecords = useQuery<TAppointment[], AxiosError>(
    [appointments.model, currentPage, filters?.loadRecords],
    () =>
      appointmentServiceHandlers?.loadRecords({
        dynamicFilters: filters?.loadRecords,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
    },
  )

  return {
    loadRecords,
  }
}
