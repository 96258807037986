import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import { Pagination } from '@/core/types'
import { createInjectionState, toReactive } from '@vueuse/core'
import { computed, ref } from 'vue'
import type { TAppointment } from '../../../types'
import { useAppointmentQuery } from '../query/useAppointmentQuery'
import { useCatalogQuery } from '../query/useCatalogQuery'

const [useProvideAppointmentModule, useAppointmentModule] =
  createInjectionState(() => {
    const rows = ref<number[]>([])
    const form = ref<TAppointment>({})
    const filters = ref<ToQueryStringFilters>({
      project_id: [],
      advisor_id: [],
      start_date: '',
      end_date: '',
      param: '',
    })
    const projectFilters = ref<ToQueryStringFilters>({
      param: '',
      status_id: [1],
    })

    const advisorFilters = ref<ToQueryStringFilters>({
      param: '',
    })

    const moduleQueryParams = useToQueryString(toReactive(filters), {
      dateSettings: {
        enableFormat: true,
      },
    })

    const projectsQueryParams = useToQueryString(toReactive(projectFilters), {
      searchColumns: ['name'],
    })

    const advisorsQueryParams = useToQueryString(toReactive(advisorFilters), {
      searchColumns: ['name'],
    })

    const modulePagination = usePagination()

    const catalogQuery = useCatalogQuery({
      filters: {
        projectsCatalog: projectsQueryParams.queryParams,
        advisorsCatalog: advisorsQueryParams.queryParams,
      },
      enableQuery: {
        campaignsCatalog: false,
        originsCatalog: false,
        statusesCatalog: false,
        advisorsCatalog: true,
        projectsCatalog: computed(
          () => !!projectsQueryParams.params.value.length,
        ),
      },
    })

    const appointmentModuleQuery = useAppointmentQuery({
      filters: {
        loadRecords: moduleQueryParams.queryParams,
        projectsCatalog: projectsQueryParams.queryParams,
        advisorsCatalog: advisorsQueryParams.queryParams,
      },
      enableQuery: {
        loadRecords: computed(() => moduleQueryParams.params.value.length > 1),
      },
      handlers: {
        onLoadRecords: (pagination: Pagination) => {
          modulePagination.setPagination(pagination)
        },
      },
    })

    const { orderByOptions } = useOrderByFilter({
      columns: ['created_at', 'email'],
    })

    const appointments = computed(() => {
      const appointmentsLists = appointmentModuleQuery.loadRecords?.data.value
      return appointmentsLists?.map(
        (appointment: TAppointment, index: number) => {
          return {
            key: appointment?.id ?? index,
            dates: [new Date(appointment?.appointment_date ?? '')],
            customData: appointment,
          }
        },
      )
    })

    const advisorsCatalog = computed(
      () => catalogQuery.advisorsCatalog.data?.value,
    )

    const projectsCatalog = computed(
      () => catalogQuery.projectsCatalog.data?.value,
    )

    const isLoading = computed(
      () => appointmentModuleQuery.loadRecords.isLoading.value,
    )

    return {
      appointments,
      advisorsCatalog,
      projectsCatalog,
      filters,
      orderByOptions,
      rows,
      form,
      isLoading,
      projectFilters,
      advisorFilters,
      modulePagination: toReactive(modulePagination),
    }
  })

export { useAppointmentModule, useProvideAppointmentModule }
