import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import { Pagination, TRecordAction } from '@/core/types'
import { createInjectionState, toReactive } from '@vueuse/core'
import { computed, ref } from 'vue'
import type { TCustomer } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'
import { useRoute } from 'vue-router'

const [useProvideModule, useModule] = createInjectionState(() => {
  const rows = ref<number[]>([])
  const form = ref<TCustomer>({})
  const filters = ref<ToQueryStringFilters>({
    param: '',
    dates: {
      created_at: [],
    },
    order_by: 'created_at,desc',
  })

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['name', 'paternal_last_name', 'maternal_last_name'],
    dateSettings: {
      enableFormat: true,
    },
  })

  const modulePagination = usePagination()

  const route = useRoute()

  const isDetail = computed(() => route.name === 'customerDetail')

  const isBackground = computed(() => route.name === 'customerBackgrounds')

  const isDocuments = computed(() => route.name === 'customerDetailDocuments')

  const moduleQuery = useModuleQuery({
    currentPage: modulePagination.currentPage,
    modelId: computed(() => route.params?.id?.toString()),
    filters: {
      loadRecords: moduleQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: !isDetail.value,
      loadRecord: isDetail.value || isBackground.value || isDocuments.value,
      loadCoOwnershipsRecords: isDetail,
    },
    handlers: {
      onLoadRecords: (pagination: Pagination) => {
        modulePagination.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at'],
  })

  const onAdd = async (payload: TCustomer) => {
    await moduleQuery.addRecord.mutateAsync(payload)
  }

  const onEdit = async (payload: TCustomer) => {
    await moduleQuery.editRecord.mutateAsync(payload)
  }

  const onDeleteMany = async () => {
    await moduleQuery.deleteRecords.mutateAsync(rows.value)
  }

  const onDelete = async (payload: TCustomer) => {
    await moduleQuery.deleteRecord.mutateAsync(payload)
  }

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
  }

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)

  const model = computed(() => moduleQuery.loadRecord.data.value)

  const coOnwnerships = computed(
    () => moduleQuery.loadCoOwnershipsRecords.data.value,
  )

  const isLoading = computed(() => {
    if (isDetail.value) {
      return (
        moduleQuery.loadCoOwnershipsRecords.isLoading.value ||
        moduleQuery.loadRecord.isLoading.value
      )
    }
    return (
      moduleQuery.addRecord.isLoading.value ||
      moduleQuery.editRecord.isLoading.value ||
      moduleQuery.loadRecords.isLoading.value
    )
  })

  return {
    tableData,
    filters,
    orderByOptions,
    rows,
    form,
    isLoading,
    modulePagination: toReactive(modulePagination),
    model,
    coOnwnerships,
    isBackground,
    isDocuments,
    onAction,
    onDelete,
    onDeleteMany,
    onEdit,
    onAdd,
  }
})

export { useModule, useProvideModule }
