import {
  createCrudEndpoints,
  createExportEndpoint,
  createCatalogEndpoint,
} from '@/core/utils'
import { TAdvisor, TAdvisorType, TCatalogs } from '.'
import { DataWithPagination } from '@/core/types'

export type TAdvisors = DataWithPagination<TAdvisor>

export type TAdvisorTypes = TAdvisorType[]

export const serviceConfig = {
  model: 'advisors',
  version: 'v1',
}

const moduleServiceHandlers = createCrudEndpoints<TAdvisors, TAdvisor>(
  serviceConfig.model,
  serviceConfig.version,
)
const moduleExportHandler = createExportEndpoint(serviceConfig.model)

const categoriesCatalog = createCatalogEndpoint<TCatalogs>(
  '',
  serviceConfig.version,
)

export { moduleServiceHandlers, moduleExportHandler, categoriesCatalog }
