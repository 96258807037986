import { createInjectionState } from '@vueuse/core'
import { computed, ref } from 'vue'
import type { TCustomer } from '../../../types'
import { useCustomerQuery } from '../query/useCustomerQuery'
import { useRoute } from 'vue-router'

const [useProvideCustomer, useCustomerModule] = createInjectionState(() => {
  const form = ref<TCustomer>({})
  const route = useRoute()

  const customerQuery = useCustomerQuery({
    modelId: computed(() => route.params?.id?.toString()),
    enableQuery: {
      loadRecords: true,
    },
  })

  const onAdd = async (payload: TCustomer) => {
    await customerQuery.addRecord.mutateAsync({
      ...payload,
      sale_id: route.params?.id.toString(),
    })
    await customerQuery.loadRecords.refetch()
  }

  const onEdit = async (payload: TCustomer) => {
    await customerQuery.editRecord.mutateAsync({
      ...payload,
      sale_id: route.params?.id.toString(),
    })
    await customerQuery.loadRecords.refetch()
  }

  const onDelete = async (payload: TCustomer) => {
    await customerQuery.deleteRecord.mutateAsync(payload)
    await customerQuery.loadRecords.refetch()
  }

  const tableData = computed(() => customerQuery.loadRecords.data?.value)

  const isLoading = computed(
    () =>
      customerQuery.loadRecords.isLoading.value ||
      customerQuery.addRecord.isLoading.value ||
      customerQuery.editRecord.isLoading.value,
  )

  return {
    form,
    isLoading,
    tableData,
    onEdit,
    onAdd,
    onDelete,
  }
})

export { useProvideCustomer, useCustomerModule }
