import api from '@/core/config/axios'
import { DataWithPagination, TFilters } from '@/core/types'
import {
  createCatalogEndpoint,
  createCrudEndpoints,
  createNestedCrudEndpoints,
} from '@/core/utils'
import { toValue } from 'vue'
import {
  TAdvisors,
  TAppointment,
  TAppointments,
  TAppointmentsCatalog,
  TCampaigns,
  TCatalogs,
  TComment,
  TLead,
  TOrigins,
  TProject,
  TQuote,
  TStatuses,
} from '.'
import { TLot, TLots } from '../projects'

export type TLeads = DataWithPagination<TLead>
export type TComments = TComment[]
export type TQuotations = DataWithPagination<TQuote>

export const serviceConfig = {
  model: 'leads',
  version: 'v1',
  comments: {
    model: 'comments',
    version: 'v1',
  },
  projects: {
    model: 'projects',
    version: 'v1',
  },
  appointmentTypes: {
    model: 'appointment-types',
    version: 'v1',
  },
  appointments: {
    model: 'appointments',
    version: 'v1',
  },
  advisors: {
    model: 'advisors',
    version: 'v1',
  },
  origins: {
    model: 'origins',
    version: 'v1',
  },
  campaigns: {
    model: 'campaigns',
    version: 'v1',
  },
  statuses: {
    model: 'status',
    version: 'v1',
  },
  quotations: {
    model: 'quotations',
    version: 'v1',
  },
  lots: {
    model: 'lots',
    version: 'v1',
  },
}

const {
  comments,
  projects,
  appointmentTypes,
  appointments,
  advisors,
  origins,
  campaigns,
  statuses,
  quotations,
  lots,
} = serviceConfig

const moduleServiceHandlers = createCrudEndpoints<TLeads, TLead>(
  serviceConfig.model,
  serviceConfig.version,
)

const commentsServiceHandlers = createNestedCrudEndpoints<TComments, TComment>(
  serviceConfig.model,
  comments.model,
  comments.version,
)

const advisorsServiceHandler = createCatalogEndpoint<TAdvisors>(
  advisors.model,
  advisors.version,
)

const originsServiceHandler = createCatalogEndpoint<TOrigins>(
  origins.model,
  origins.version,
)

const campaignsServiceHandler = createCatalogEndpoint<TCampaigns>(
  campaigns.model,
  campaigns.version,
)

const statusesServiceHandler = createCatalogEndpoint<TStatuses>(
  statuses.model,
  statuses.version,
)

const projectsServiceHandler = createCatalogEndpoint<TProject[]>(
  projects.model,
  projects.version,
)

const appointmentTypesServiceHandler =
  createCatalogEndpoint<TAppointmentsCatalog>(
    appointmentTypes.model,
    appointmentTypes.version,
  )

const appointmentsServiceHandler = createCrudEndpoints<
  TAppointments,
  TAppointment
>(serviceConfig.appointments.model, serviceConfig.appointments.version)

const categoriesCatalog = createCatalogEndpoint<TCatalogs>(
  '',
  serviceConfig.version,
)

const lotsServiceHandler = createCatalogEndpoint<TLots>(
  lots.model,
  lots.version,
)

const projectsLotsService = createNestedCrudEndpoints<TLots, TLot>(
  projects.model,
  lots.model,
  lots.version,
)

const catalogServiceHandlers = {
  statusesServiceHandler,
  campaignsServiceHandler,
  originsServiceHandler,
  advisorsServiceHandler,
  projectsServiceHandler,
  appointmentTypesServiceHandler,
  appointmentsServiceHandler,
  categoriesCatalog,
  lotsServiceHandler,
  projectsLotsService,
}

const appointmentServiceHandlers = {
  loadRecords: async (params: TFilters): Promise<TAppointment[]> =>
    api
      .get(
        `${appointments.version}/${appointments.model}?${toValue(
          params?.dynamicFilters,
        )}`,
      )
      .then((response) => response.data),
}

const quotationsServiceHandlers = createNestedCrudEndpoints<
  TQuotations,
  TQuote
>(serviceConfig.model, quotations.model, quotations.version)

const quotesActionsService = createCrudEndpoints<TQuotations, TQuote>(
  quotations.model,
  quotations.version,
)

export {
  appointmentServiceHandlers,
  catalogServiceHandlers,
  commentsServiceHandlers,
  moduleServiceHandlers,
  quotationsServiceHandlers,
  quotesActionsService,
}
