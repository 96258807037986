import type { BaseServiceOptions, Pagination } from '@/core/types'
import {
  commentsServiceHandlers,
  serviceConfig,
  type TComment,
  type TComments,
} from '../../..'
import { useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { toValue } from 'vue'

const { model } = serviceConfig.comments

export const useCommentsQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage = 1, enableQuery, filters, modelId } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TComment>({
    model,
    currentPage: toValue(currentPage) ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const addRecord = useMutation<TComment, AxiosError, TComment>(
    (payload: TComment) =>
      commentsServiceHandlers.addRecord(payload, { modelId }),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TComments, AxiosError>(
    [model, toValue(currentPage), filters?.loadRecords],
    () =>
      commentsServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        modelId,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
      select: (payload) =>
        payload.map((comment) => {
          const names = comment.author?.split(' ')
          const authorInitials =
            (names?.length ?? 0) >= 2
              ? `${names?.[0].charAt(0)}${names?.[1].charAt(0)}`
              : `${names?.[0].charAt(0)}`

          return {
            ...comment,
            author_initials: authorInitials,
          }
        }),
    },
  )

  return {
    loadRecords,
    addRecord,
  }
}
