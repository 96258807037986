import {
  leadStatusIds,
  ToQueryStringFilters,
  useDate,
  useToQueryString,
} from '@/core'
import { useCatalogStore } from '@/core/store/catalogs'
import { createInjectionState, toReactive } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import type { TComment, TLead } from '../../../types'
import { useCatalogQuery } from '../query/useCatalogQuery'
import { useCommentsQuery } from '../query/useCommentsQuery'
import { useModuleQuery } from '../query/useModuleQuery'

const [useProvideLeadModule, useLeadModule] = createInjectionState(() => {
  const { zonedTimeToUtc } = useDate()
  const form = ref<TLead>({})
  const filtersStatus = ref<ToQueryStringFilters>({
    param: '',
    id: Object.values(leadStatusIds),
  })
  const projectFilters = ref<ToQueryStringFilters>({
    param: '',
    status_id: [1],
  })

  const store = useCatalogStore()
  const { catalogs } = storeToRefs(store)

  const statusQueryParams = useToQueryString(toReactive(filtersStatus))
  const projectsQueryParams = useToQueryString(toReactive(projectFilters), {
    searchColumns: ['name'],
  })

  const route = useRoute()
  const router = useRouter()

  const isEdit = computed(() => route.name === 'leadsDetails')
  const isViewComments = computed(() => route.name === 'leadComments')
  const isCreate = computed(() => route.name === 'leadsCreate')

  const catalogQuery = useCatalogQuery({
    filters: {
      statusesCatalog: statusQueryParams.queryParams,
      projectsCatalog: projectsQueryParams.queryParams,
    },
    enableQuery: {
      advisorsCatalog: computed(() => isEdit.value || isCreate.value),
      campaignsCatalog: computed(() => isEdit.value || isCreate.value),
      originsCatalog: computed(() => isEdit.value || isCreate.value),
      statusesCatalog: computed(() => isEdit.value || isCreate.value),
      projectsCatalog: computed(() => isEdit.value || isCreate.value),
      lotsCatalog: false,
    },
  })

  const moduleQuery = useModuleQuery({
    modelId: computed(() => route.params?.id?.toString()),
    enableQuery: {
      loadRecords: false,
      loadRecord: computed(
        () => !!route.params?.id && (isEdit.value || isViewComments.value),
      ),
    },
    handlers: {
      onLoadRecord: (data) => {
        form.value = data
      },
    },
  })

  const commentsQuery = useCommentsQuery({
    modelId: computed(() => Number(form.value.id)),
    enableQuery: {
      loadRecords: computed(() => !isNaN(Number(form.value.id))),
    },
  })

  const onAdd = async (payload: TLead) => {
    await moduleQuery.addRecord.mutateAsync(payload).then((lead) => {
      onRedirect(lead)
    })
  }

  const onAddComment = async (payload: TComment) => {
    await commentsQuery.addRecord.mutateAsync({
      ...payload,
      is_appointment: !!payload?.is_appointment,
      advisor_id: model.value?.advisor_id,
      ...(payload?.appointment_date
        ? {
            appointment_date: zonedTimeToUtc(
              new Date(payload?.appointment_date),
              {
                removeTimeZone: true,
              },
            ),
          }
        : {}),
    })

    await commentsQuery.loadRecords.refetch()
  }

  const onEdit = async (payload: TLead) => {
    await moduleQuery.editRecord.mutateAsync(payload)
    await moduleQuery.loadRecord.refetch()
  }

  const onRedirect = (payload: TLead) => {
    router.replace({ name: 'leadsDetails', params: { id: payload.id } })
  }

  const onRequestCatalogs = async (payload: number[]) => {
    await catalogQuery.requestCatalogs.mutateAsync({
      ids: payload,
    })
  }

  const onRefetchComments = () => {
    commentsQuery.loadRecords.refetch()
  }

  const isLoading = computed(() => {
    if (isEdit.value) {
      return (
        moduleQuery.addRecord.isLoading.value ||
        moduleQuery.editRecord.isLoading.value ||
        moduleQuery.loadRecord.isLoading.value ||
        moduleQuery.loadRecord.isFetching.value ||
        commentsQuery.loadRecords.isLoading.value
      )
    }

    if (isViewComments.value) {
      return (
        moduleQuery.loadRecord.isLoading.value ||
        moduleQuery.loadRecord.isFetching.value ||
        commentsQuery.loadRecords.isLoading.value
      )
    }

    return (
      catalogQuery.campaignsCatalog.isLoading.value ||
      catalogQuery.advisorsCatalog.isLoading.value ||
      catalogQuery.statusesCatalog.isLoading.value
    )
  })
  const model = computed(() => moduleQuery.loadRecord.data.value)
  const comments = computed(() => commentsQuery.loadRecords.data.value)
  const isCommentsLoading = computed(
    () =>
      commentsQuery.addRecord.isLoading.value ||
      commentsQuery.loadRecords.data.value,
  )
  const advisorsCatalog = computed(
    () => catalogQuery.advisorsCatalog.data?.value,
  )

  const statusesCatalog = computed(
    () => catalogQuery.statusesCatalog.data?.value,
  )
  const campaignsCatalog = computed(
    () => catalogQuery.campaignsCatalog.data?.value,
  )

  const projectsCatalog = computed(
    () => catalogQuery.projectsCatalog.data?.value,
  )

  return {
    form,
    isLoading,
    isCommentsLoading,
    advisorsCatalog,
    statusesCatalog,
    campaignsCatalog,
    projectsCatalog,
    comments,
    model,
    catalogs,
    isEdit,
    onEdit,
    onAdd,
    onAddComment,
    onRequestCatalogs,
    onRefetchComments,
  }
})

export { useLeadModule, useProvideLeadModule }
