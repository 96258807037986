import { authRoles } from '@/core'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN, ADVISOR } = authRoles

const Dashboard = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/index.vue')

export const dashboardModuleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Inicio',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/advisors',
      },
    },
  },
]
