import { createCrudEndpoints, createNestedCrudEndpoints } from '@/core/utils'
import {
  ServiceParams,
  TCustomer,
  TCustomerFile,
  TCustomerFiles,
  TCustomerProperties,
  TCustomerProperty,
  TCustomers,
} from '.'
import { TComment, TComments } from '../leads'
import api from '@/core/config/axios'
import { toValue } from 'vue'

export const serviceConfig = {
  model: 'customers',
  version: 'v1',
  coOwnerships: {
    model: 'co-ownerships',
    version: 'v1',
  },
  customerFiles: {
    model: 'customer-files',
    version: 'v1',
  },
  backgrounds: {
    model: 'backgrounds',
    version: 'v1',
  },
}

const { coOwnerships, customerFiles, backgrounds } = serviceConfig

const moduleServiceHandlers = createCrudEndpoints<TCustomers, TCustomer>(
  serviceConfig.model,
  serviceConfig.version,
)

const customerCoOwnershipsHandlers = createNestedCrudEndpoints<
  TCustomerProperties,
  TCustomerProperty
>(serviceConfig.model, coOwnerships.model, coOwnerships.version)

const customerFilesHandlers = createCrudEndpoints<
  TCustomerFiles,
  TCustomerFile
>(customerFiles.model, customerFiles.version)

const customerBackgroundsHandlers = createNestedCrudEndpoints<
  TComments,
  TComment
>(serviceConfig.model, backgrounds.model, backgrounds.version)

const uploadServiceHandlers = {
  uploadDocument: async (
    params: ServiceParams,
    payload: FormData,
  ): Promise<void> =>
    api
      .post(
        `v1/customers/${toValue(params.modelId)}/files/${toValue(
          params.fileId,
        )}`,
        payload,
      )
      .then((response) => response.data),
}

export {
  moduleServiceHandlers,
  customerCoOwnershipsHandlers,
  customerFilesHandlers,
  customerBackgroundsHandlers,
  uploadServiceHandlers,
}
