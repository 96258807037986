import { DataWithPagination } from '@/core/types'
import { createCrudEndpoints } from '@/core/utils'
import { TBusinessName } from '.'

export type TBusinessNames = DataWithPagination<TBusinessName>

export const serviceConfig = {
  model: 'business-names',
  version: 'v1',
}

const moduleServiceHandlers = createCrudEndpoints<
  TBusinessNames,
  TBusinessName
>(serviceConfig.model, serviceConfig.version)

export { moduleServiceHandlers }
