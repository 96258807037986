import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import { useCatalogStore } from '@/core/store/catalogs'
import { Pagination, TImportFile, TRecordAction } from '@/core/types'
import { createInjectionState, toReactive } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import type { TAdvisor /* TAdvisorType */ } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'

const [useProvideModule, useModule] = createInjectionState(() => {
  const rows = ref<number[]>([])
  const form = ref<TAdvisor>({})
  const filters = ref<ToQueryStringFilters>({
    param: '',
    dates: {
      created_at: [],
    },
    order_by: 'created_at,desc',
  })
  const store = useCatalogStore()
  const { catalogs } = storeToRefs(store)

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['name', 'email'],
    dateSettings: {
      enableFormat: true,
    },
  })

  const modulePagination = usePagination()

  const moduleQuery = useModuleQuery({
    currentPage: modulePagination.currentPage,
    filters: {
      loadRecords: moduleQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: true,
    },
    handlers: {
      onLoadRecords: (pagination: Pagination) => {
        modulePagination.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at', 'email'],
  })

  const onAdd = async (payload: TAdvisor) => {
    await moduleQuery.addRecord.mutateAsync(payload)
  }

  const onEdit = async (payload: TAdvisor) => {
    await moduleQuery.editRecord.mutateAsync(payload)
  }

  const onDeleteMany = async () => {
    await moduleQuery.deleteRecords.mutateAsync(rows.value)
  }

  const onDelete = async (payload: TAdvisor) => {
    await moduleQuery.deleteRecord.mutateAsync(payload)
  }

  const onExport = async () => {
    await moduleQuery.exportRecords()
  }

  const onImport = async (data: TImportFile) => {
    await moduleQuery.importRecords.mutateAsync(data)
  }

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
  }

  const onRequestCatalogs = async (payload: number[]) => {
    await moduleQuery.requestCatalogs.mutateAsync({
      ids: payload,
    })
  }

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)

  const isLoading = computed(
    () =>
      moduleQuery.addRecord.isLoading.value ||
      moduleQuery.editRecord.isLoading.value ||
      moduleQuery.loadRecords.isLoading.value,
  )

  return {
    tableData,
    filters,
    orderByOptions,
    rows,
    form,
    isLoading,
    modulePagination: toReactive(modulePagination),
    catalogs,
    onRequestCatalogs,
    onImport,
    onAction,
    onExport,
    onDelete,
    onDeleteMany,
    onEdit,
    onAdd,
  }
})

export { useModule, useProvideModule }
