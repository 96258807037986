// import { AxiosResponse } from 'axios'
// import { ref } from 'vue'

// type HTTPData = {
//   message: string
//   errors?: string[]
//   status: number
// }

// const flattenErrors = (errors: any): string[] => {
//   const flattened: string[] = []

//   const flattenRecursive = (obj: any) => {
//     for (const key in obj) {
//       if (typeof obj[key] === 'string') {
//         flattened.push(obj[key])
//       } else if (Array.isArray(obj[key])) {
//         obj[key].forEach((error: string) => {
//           flattened.push(error)
//         })
//       } else if (typeof obj[key] === 'object') {
//         flattenRecursive(obj[key])
//       }
//     }
//   }

//   flattenRecursive(errors)
//   return flattened
// }

// export const useParserHTTPErrors = (axiosResponse: AxiosResponse) => {
//   const parsedError = ref<HTTPData>({
//     message: axiosResponse.data?.message ?? 'Ups!',
//     errors: [],
//     status: axiosResponse?.status ?? 0,
//   })

//   if (axiosResponse.statusText) {
//     parsedError.value.errors = [axiosResponse?.statusText || '']
//   }

//   if (axiosResponse?.data) {
//     if (axiosResponse.data?.errors) {
//       const errors = axiosResponse.data.errors
//       parsedError.value.errors = flattenErrors(errors)
//     }

//     if (axiosResponse.data?.error) {
//       parsedError.value.message = axiosResponse.data?.error?.header || ''
//       parsedError.value.errors = [
//         ...(parsedError.value.errors ?? []),
//         axiosResponse.data?.error?.message,
//       ]
//     }
//   }

//   return {
//     parsedError,
//   }
// }

import { AxiosResponse } from 'axios'
import { ref } from 'vue'

type HTTPData = {
  message: string | string[]
  errors?: string[]
  status: number
}

export const useParserHTTPErrors = (axiosResponse: AxiosResponse) => {
  const parsedError = ref<HTTPData>({
    message: axiosResponse?.data?.error ?? 'Ups!',
    errors: [],
    status: axiosResponse?.data?.statusCode ?? 500,
  })

  if (axiosResponse?.data?.message) {
    if (typeof axiosResponse.data?.message === 'string') {
      parsedError.value?.errors?.push(axiosResponse.data?.message)
    }
    if (Array.isArray(axiosResponse.data?.message)) {
      parsedError.value?.errors?.push(...(axiosResponse.data?.message ?? []))
    }
  }

  return {
    parsedError,
  }
}
