import { useNotification } from '@/core'
import type { BaseServiceOptions, Pagination } from '@/core/types'
import { TLeads } from '@/modules/leads'
import { useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import {
  leadServiceHandlers,
  lotsServiceHandler,
  projectsCatalogService,
  serviceConfig,
  TLots,
  TProjects,
} from '../../..'

const { projects, lots, leads } = serviceConfig

export const useCatalogQuery = (options: BaseServiceOptions<Pagination>) => {
  const { enableQuery, filters } = options

  const { errorHttpNotification } = useNotification()

  const projectsCatalog = useQuery<TProjects, AxiosError>(
    [projects.model, projects.version, filters?.projectsCatalog],
    () =>
      projectsCatalogService.loadCatalog({
        dynamicFilters: filters?.projectsCatalog,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.projectsCatalog,
      onError: (error) => errorHttpNotification(error),
    },
  )

  const lotsCatalog = useQuery<TLots, AxiosError>(
    [lots.model, lots.version, filters?.lotsCatalog],
    () =>
      lotsServiceHandler.loadRecords({
        dynamicFilters: filters?.lotsCatalog,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.lotsCatalog,
      onError: (error) => errorHttpNotification(error),
    },
  )

  const leadsCatalog = useQuery<TLeads, AxiosError>(
    [leads.model, leads.version],
    () => leadServiceHandlers.loadCatalog({}),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.leadsCatalog,
      onError: (error) => errorHttpNotification(error),
    },
  )

  return {
    projectsCatalog,
    lotsCatalog,
    leadsCatalog,
  }
}
