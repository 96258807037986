import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import { Pagination } from '@/core/types'
import { createInjectionState, toReactive } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import type { TLot } from '../../../types'
import { useLotQuery } from '../query/useLotQuery'

const [useProvideLotModule, useLotModule] = createInjectionState(() => {
  const route = useRoute()
  const rows = ref<number[]>([])
  const form = ref<TLot>({})
  const lotFilters = ref<ToQueryStringFilters>({
    param: '',
    dates: {
      created_at: [],
    },
    project_id: route.params?.id ? [route.params?.id] : [],
    order_by: 'created_at,desc',
  })

  const lotQueryParams = useToQueryString(toReactive(lotFilters), {
    searchColumns: ['name'],
    dateSettings: {
      enableFormat: true,
    },
  })

  const modulePagination = usePagination()

  const lotQuery = useLotQuery({
    modelId: computed(() => route.params?.id?.toString()),
    currentPage: modulePagination.currentPage,
    filters: {
      loadRecords: lotQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: true,
    },
    handlers: {
      onLoadRecords: (pagination: Pagination) => {
        modulePagination.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at', 'sold'],
  })

  const onAdd = async (payload: TLot) => {
    await lotQuery.addRecord.mutateAsync({
      ...payload,
      housing_commercial: !!payload?.housing_commercial,
      sold: !!payload?.sold,
    })
  }

  const onEdit = async (payload: TLot) => {
    await lotQuery.editRecord.mutateAsync(payload)
  }

  const onDelete = async (payload: TLot) => {
    await lotQuery.deleteRecord.mutateAsync(payload)
  }

  const tableData = computed(() => lotQuery.loadRecords.data?.value?.data)

  const isLoading = computed(() => {
    return (
      lotQuery.addRecord.isLoading.value ||
      lotQuery.editRecord.isLoading.value ||
      lotQuery.loadRecords.isLoading.value ||
      lotQuery.loadRecords.isFetching.value
    )
  })

  return {
    form,
    isLoading,
    orderByOptions,
    tableData,
    rows,
    lotFilters,
    modulePagination: toReactive(modulePagination),
    onDelete,
    onAdd,
    onEdit,
  }
})

export { useLotModule, useProvideLotModule }
