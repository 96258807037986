import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import type {
  BaseServiceOptions,
  Pagination,
  TRecordAction,
} from '@/core/types'
import { TBusinessName } from '@/modules/business-names'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import {
  type TCoOwner,
  type TCoOwners,
  businessNamesService,
  moduleServiceHandlers,
  serviceConfig,
} from '../../..'

const { model, businessNames } = serviceConfig

export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, handlers, enableQuery, filters } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TCoOwner>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const editRecord = useMutation<TCoOwner, AxiosError, TCoOwner>(
    (payload: TCoOwner) =>
      moduleServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TCoOwner, AxiosError, TCoOwner>(
    (payload: TCoOwner) => moduleServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TCoOwner, AxiosError, TRecordAction>(
    (payload: TRecordAction) => moduleServiceHandlers.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TCoOwners, AxiosError>(
    [model, currentPage, filters?.loadRecords],
    () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
      onSuccess: (data) => {
        if (data.meta) {
          handlers?.onLoadRecords?.(data.meta ?? defaultPagination)
        }
      },
    },
  )

  const businessNamesCatalog = useQuery<TBusinessName, AxiosError>(
    [businessNames.model, businessNames.version, filters?.projectsCatalog],
    () =>
      businessNamesService.loadCatalog({
        dynamicFilters: filters?.projectsCatalog,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.projectsCatalog,
      onError: (error) => errorHttpNotification(error),
    },
  )

  return {
    loadRecords,
    addRecord,
    editRecord,
    actionRecord,
    businessNamesCatalog,
  }
}
