import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import type { BaseServiceOptions, Pagination } from '@/core/types'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { toValue } from 'vue'
import { type TLot, type TLots, lotsService, serviceConfig } from '../../..'

const { lots } = serviceConfig

export const useLotQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, handlers, enableQuery, filters, modelId } = options

  const { errorHttpNotification } = useNotification()

  const serviceHandlers = useServiceHandlers<TLot>({
    model: lots.model,
    currentPage: toValue(currentPage) ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const editRecord = useMutation<TLot, AxiosError, TLot>(
    (payload: TLot) =>
      lotsService.editRecord(payload, {
        modelId,
        modelChildId: payload.id,
      }),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TLot, AxiosError, TLot>(
    (payload: TLot) => lotsService.addRecord(payload, { modelId }),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const deleteRecord = useMutation<void, AxiosError, TLot>(
    (payload) =>
      lotsService.deleteRecord({
        modelId,
        modelChildId: payload.id,
      }),
    {
      onSuccess: (_, lot) => serviceHandlers.deleteRecord(lot),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TLots, AxiosError>(
    [lots.model, currentPage, filters?.loadRecords],
    () =>
      lotsService.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
        modelId,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
      onSuccess: (data) => {
        if (data.meta) {
          handlers?.onLoadRecords?.(data.meta ?? defaultPagination)
        }
      },
    },
  )

  return {
    loadRecords,
    addRecord,
    editRecord,
    deleteRecord,
  }
}
