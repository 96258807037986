import { defineStore } from 'pinia'
import { ref } from 'vue'
import { TBaseCatalogs } from '../types'

export const useCatalogStore = defineStore('catalogs', () => {
  const catalogs = ref<TBaseCatalogs>({
    follow_up_types: [],
    advisor_types: [],
    medias: [],
    origins: [],
    organic: [],
    campaign: [],
  })

  const setCatalogs = (payload: TBaseCatalogs) => {
    catalogs.value = {
      ...catalogs.value,
      ...payload,
    }
  }

  return {
    setCatalogs,
    catalogs,
  }
})
