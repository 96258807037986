import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import { useCatalogStore } from '@/core/store/catalogs'
import type {
  BaseServiceOptions,
  Pagination,
  TImportFile,
  TRecordAction,
  TRequestCatalog,
} from '@/core/types'
import { TProjects } from '@/modules/projects'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import {
  type TCampaign,
  type TCampaigns,
  catalogServiceHandler,
  moduleExportHandler,
  moduleServiceHandlers,
  projectsCatalogService,
  referralsCatalogService,
  serviceConfig,
  socialNetworksCatalogService,
  TSocialNetworks,
} from '../../..'
import { TCatalogs } from './../../../../advisors/types'
import { TReferrals } from './../../../types'

const { model, projects, referrals, socialNetworks } = serviceConfig

const { categoriesCatalog } = catalogServiceHandler

export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, handlers, enableQuery, filters } = options
  const store = useCatalogStore()
  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TCampaign>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const deleteRecord = useMutation<void, AxiosError, TCampaign>(
    (payload) => moduleServiceHandlers.deleteRecord(payload),
    {
      onSuccess: (_, user) => serviceHandlers.deleteRecord(user),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const deleteRecords = useMutation<void, AxiosError, Array<number>>(
    (payload) => moduleServiceHandlers.deleteRecords({ ids: payload }),
    {
      onSuccess: (_, ids) => serviceHandlers.deleteRecords(ids),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const editRecord = useMutation<TCampaign, AxiosError, TCampaign>(
    (payload: TCampaign) =>
      moduleServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TCampaign, AxiosError, TCampaign>(
    (payload: TCampaign) => moduleServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TCampaign, AxiosError, TRecordAction>(
    (payload: TRecordAction) => moduleServiceHandlers.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const importRecords = useMutation<void, AxiosError, TImportFile>(
    (payload: TImportFile) => {
      const formData = new FormData()
      formData.append('importFile', payload.file)
      return moduleServiceHandlers.importRecords(formData)
    },
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const requestCatalogs = useMutation<TCatalogs, AxiosError, TRequestCatalog>(
    (payload: TRequestCatalog) => categoriesCatalog.requestCatalog(payload),
    {
      onSuccess: (payload) => {
        store.setCatalogs(payload)
      },
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TCampaigns, AxiosError>(
    [model, currentPage, filters?.loadRecords],
    () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
      onSuccess: (data) => {
        if (data.meta) {
          handlers?.onLoadRecords?.(data.meta ?? defaultPagination)
        }
      },
      select: (payload) => ({
        ...payload,
        data: payload.data.map((record) => ({
          ...record,
          initial_date: new Date(record.initial_date),
          end_date: new Date(record.end_date),
        })),
      }),
    },
  )

  const projectsCatalog = useQuery<TProjects, AxiosError>(
    [projects.model, projects.version],
    () =>
      projectsCatalogService.loadCatalog({
        dynamicFilters: filters?.projectsCatalog,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.projectsCatalog,
      onError: (error) => errorHttpNotification(error),
    },
  )

  const referralsCatalog = useQuery<TReferrals, AxiosError>(
    [referrals.model, referrals.version],
    () => referralsCatalogService.loadCatalog({}),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.referralsCatalog,
      onError: (error) => errorHttpNotification(error),
    },
  )

  const socialNetworksCatalog = useQuery<TSocialNetworks, AxiosError>(
    [socialNetworks.model, socialNetworks.version],
    () => socialNetworksCatalogService.loadCatalog({}),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.socialNetworksCatalog,
      onError: (error) => errorHttpNotification(error),
    },
  )

  const exportRecords = async () =>
    await moduleExportHandler.exportRecords({
      dynamicFilters: filters?.loadRecords,
    })

  return {
    loadRecords,
    addRecord,
    editRecord,
    deleteRecords,
    deleteRecord,
    importRecords,
    actionRecord,
    requestCatalogs,
    projectsCatalog,
    referralsCatalog,
    socialNetworksCatalog,
    exportRecords,
  }
}
