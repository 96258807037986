import { authRoles } from '@/core'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN, ADVISOR } = authRoles

const Sales = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/index.vue')

const SalesAssignProject = () =>
  import(
    /* webpackChunkName: "login-view" */ './views/pages/sale-process/assign-project.vue'
  )

const SalesAssignCustomer = () =>
  import(
    /* webpackChunkName: "login-view" */ './views/pages/sale-process/assign-customer.vue'
  )

const SubmitCustomerDocuments = () =>
  import(
    /* webpackChunkName: "login-view" */ './views/pages/sale-process/submit-documents.vue'
  )

const SaleDetail = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/detail.vue')

export const salesModuleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/sales',
    name: 'sales',
    component: Sales,
    meta: {
      title: 'Ventas',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: '/sales/assign-project',
    name: 'salesAssignProject',
    component: SalesAssignProject,
    meta: {
      title: 'Crear Venta',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: '/sales/:id/assign-project',
    name: 'salesAssignProjectEdit',
    component: SalesAssignProject,
    meta: {
      title: 'Editar Venta',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: '/sales/:id/assign-customer',
    name: 'salesAssignCustomer',
    component: SalesAssignCustomer,
    meta: {
      title: 'Asignar',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: '/sales/:id/submit-documents',
    name: 'submitCustomerDocuments',
    component: SubmitCustomerDocuments,
    meta: {
      title: 'Subir documentos',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: '/sales/:id/detail',
    name: 'saleDetail',
    component: SaleDetail,
    meta: {
      title: 'Detalle de Venta',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
]
