import api from '@/core/config/axios'
import { toValue } from 'vue'
import { TFilters } from '../types'

export const createCatalogEndpoint = <TModelResponse>(
  model: string,
  version?: string,
) => {
  const loadCatalog = async (params: TFilters): Promise<TModelResponse> => {
    const urlSearchParams = [
      `page=${toValue(params.page ?? 1)}`,
      toValue(params.dynamicFilters ?? ''),
    ]
      .filter(Boolean)
      .join('&')

    return api
      .get(`${version ?? ''}/catalog/${model}?${urlSearchParams}`, {
        params: {
          ...(params.page ? { page: toValue(params.page) } : {}),
        },
      })
      .then((response) => response.data)
  }

  const requestCatalog = async (
    payload: Record<any, any>,
  ): Promise<TModelResponse> =>
    api
      .post(`${version ?? ''}/catalog`, payload)
      .then((response) => response.data)

  return {
    loadCatalog,
    requestCatalog,
  }
}
