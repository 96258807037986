/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import type { App } from 'vue'
import { DotInput, DotPopupMenu } from '@/core/components'
import PrimeVue from 'primevue/config'
import AutoComplete from 'primevue/autocomplete'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Avatar from 'primevue/avatar'
import AvatarGroup from 'primevue/avatargroup'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import BlockUI from 'primevue/blockui'
import Button from 'primevue/button'
import Breadcrumb from 'primevue/breadcrumb'
import Calendar from 'primevue/calendar'
import Card from 'primevue/card'
import Chart from 'primevue/chart'
import CascadeSelect from 'primevue/cascadeselect'
import Carousel from 'primevue/carousel'
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import Chips from 'primevue/chips'
import ColorPicker from 'primevue/colorpicker'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmationService from 'primevue/confirmationservice'
import ContextMenu from 'primevue/contextmenu'
import DataTable from 'primevue/datatable'
import DataView from 'primevue/dataview'
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import DeferredContent from 'primevue/deferredcontent'
import Dialog from 'primevue/dialog'
import DialogService from 'primevue/dialogservice'
import Divider from 'primevue/divider'
import Dock from 'primevue/dock'
import Dropdown from 'primevue/dropdown'
import DynamicDialog from 'primevue/dynamicdialog'
import Fieldset from 'primevue/fieldset'
import FileUpload from 'primevue/fileupload'
import Galleria from 'primevue/galleria'
import Image from 'primevue/image'
import InlineMessage from 'primevue/inlinemessage'
import Inplace from 'primevue/inplace'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import InputMask from 'primevue/inputmask'
import InputNumber from 'primevue/inputnumber'
import Knob from 'primevue/knob'
import Listbox from 'primevue/listbox'
import MegaMenu from 'primevue/megamenu'
import Menu from 'primevue/menu'
import Menubar from 'primevue/menubar'
import Message from 'primevue/message'
import MultiSelect from 'primevue/multiselect'
import OrderList from 'primevue/orderlist'
import OrganizationChart from 'primevue/organizationchart'
import OverlayPanel from 'primevue/overlaypanel'
import Paginator from 'primevue/paginator'
import Panel from 'primevue/panel'
import PanelMenu from 'primevue/panelmenu'
import Password from 'primevue/password'
import PickList from 'primevue/picklist'
import ProgressBar from 'primevue/progressbar'
import ProgressSpinner from 'primevue/progressspinner'
import Rating from 'primevue/rating'
import RadioButton from 'primevue/radiobutton'
import Ripple from 'primevue/ripple'
import Row from 'primevue/row'
import SelectButton from 'primevue/selectbutton'
import ScrollPanel from 'primevue/scrollpanel'
import ScrollTop from 'primevue/scrolltop'
import Skeleton from 'primevue/skeleton'
import Slider from 'primevue/slider'
import Sidebar from 'primevue/sidebar'
import SpeedDial from 'primevue/speeddial'
import SplitButton from 'primevue/splitbutton'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import Steps from 'primevue/steps'
import StyleClass from 'primevue/styleclass'
import TabMenu from 'primevue/tabmenu'
import TieredMenu from 'primevue/tieredmenu'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Toolbar from 'primevue/toolbar'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Tag from 'primevue/tag'
import Terminal from 'primevue/terminal'
import Timeline from 'primevue/timeline'
import ToggleButton from 'primevue/togglebutton'
import Tooltip from 'primevue/tooltip'
import Tree from 'primevue/tree'
import TreeSelect from 'primevue/treeselect'
import TreeTable from 'primevue/treetable'
import TriStateCheckbox from 'primevue/tristatecheckbox'
import VirtualScroller from 'primevue/virtualscroller'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import MeterGroup from 'primevue/metergroup'
import { locales } from './locales'
import '@/core/assets/css/tailwind.css'
import laraPresets from './presets/lara'

const config = {
  ripple: true,
  unstyled: true,
  pt: {
    ...laraPresets,
  },
  ptOptions: { mergeProps: true },
}

export const loadPrimeComponents = (app: App) => {
  app.use(PrimeVue, { ...config, locale: locales.es })
  app.use(ToastService)
  app.use(DialogService)
  app.use(ConfirmationService)

  app.directive('tooltip', Tooltip)
  app.directive('badge', BadgeDirective)
  app.directive('ripple', Ripple)
  app.directive('styleclass', StyleClass)

  app.component('DotAccordion', Accordion)
  app.component('DotAccordionTab', AccordionTab)
  app.component('DotAutoComplete', AutoComplete)
  app.component('DotAvatar', Avatar)
  app.component('DotAvatarGroup', AvatarGroup)
  app.component('DotBadge', Badge)
  app.component('DotBlockUI', BlockUI)
  app.component('DotBreadcrumb', Breadcrumb)
  app.component('DotButton', Button)
  app.component('DotCalendar', Calendar)
  app.component('DotCard', Card)
  app.component('DotChart', Chart)
  app.component('DotCarousel', Carousel)
  app.component('DotCascadeSelect', CascadeSelect)
  app.component('DotCheckbox', Checkbox)
  app.component('DotChip', Chip)
  app.component('DotChips', Chips)
  app.component('DotColorPicker', ColorPicker)
  app.component('DotColumn', Column)
  app.component('DotColumnGroup', ColumnGroup)
  app.component('DotConfirmDialog', ConfirmDialog)
  app.component('DotConfirmPopup', ConfirmPopup)
  app.component('DotContextMenu', ContextMenu)
  app.component('DotDataTable', DataTable)
  app.component('DotDataView', DataView)
  app.component('DotDataViewLayoutOptions', DataViewLayoutOptions)
  app.component('DotDeferredContent', DeferredContent)
  app.component('DotDialog', Dialog)
  app.component('DotDivider', Divider)
  app.component('DotDock', Dock)
  app.component('DotDropdown', Dropdown)
  app.component('DotDynamicDialog', DynamicDialog)
  app.component('DotFieldset', Fieldset)
  app.component('DotFileUpload', FileUpload)
  app.component('DotGalleria', Galleria)
  app.component('DotImage', Image)
  app.component('DotInlineMessage', InlineMessage)
  app.component('DotInplace', Inplace)
  app.component('DotInput', DotInput)
  app.component('DotPopupMenu', DotPopupMenu)
  app.component('DotInputMask', InputMask)
  app.component('DotInputNumber', InputNumber)
  app.component('DotInputSwitch', InputSwitch)
  app.component('DotInputText', InputText)
  app.component('DotKnob', Knob)
  app.component('DotListbox', Listbox)
  app.component('DotMegaMenu', MegaMenu)
  app.component('DotMenu', Menu)
  app.component('DotMenubar', Menubar)
  app.component('DotMessage', Message)
  app.component('DotMultiSelect', MultiSelect)
  app.component('DotOrderList', OrderList)
  app.component('DotOrganizationChart', OrganizationChart)
  app.component('DotOverlayPanel', OverlayPanel)
  app.component('DotPaginator', Paginator)
  app.component('DotPanel', Panel)
  app.component('DotPanelMenu', PanelMenu)
  app.component('DotPassword', Password)
  app.component('DotPickList', PickList)
  app.component('DotProgressBar', ProgressBar)
  app.component('DotProgressSpinner', ProgressSpinner)
  app.component('DotRadioButton', RadioButton)
  app.component('DotRating', Rating)
  app.component('DotRow', Row)
  app.component('DotSelectButton', SelectButton)
  app.component('DotScrollPanel', ScrollPanel)
  app.component('DotScrollTop', ScrollTop)
  app.component('DotSlider', Slider)
  app.component('DotSidebar', Sidebar)
  app.component('DotSkeleton', Skeleton)
  app.component('DotSpeedDial', SpeedDial)
  app.component('DotSplitButton', SplitButton)
  app.component('DotSplitter', Splitter)
  app.component('DotSplitterPanel', SplitterPanel)
  app.component('DotSteps', Steps)
  app.component('DotTabMenu', TabMenu)
  app.component('DotTabView', TabView)
  app.component('DotTabPanel', TabPanel)
  app.component('DotTag', Tag)
  app.component('DotTextarea', Textarea)
  app.component('DotTerminal', Terminal)
  app.component('DotTieredMenu', TieredMenu)
  app.component('DotTimeline', Timeline)
  app.component('DotToast', Toast)
  app.component('DotToolbar', Toolbar)
  app.component('DotToggleButton', ToggleButton)
  app.component('DotTree', Tree)
  app.component('DotTreeSelect', TreeSelect)
  app.component('DotTreeTable', TreeTable)
  app.component('DotTriStateCheckbox', TriStateCheckbox)
  app.component('DotVirtualScroller', VirtualScroller)
  app.component('DotIconField', IconField)
  app.component('DotInputIcon', InputIcon)
  app.component('DotMeterGroup', MeterGroup)
}

export default PrimeVue
