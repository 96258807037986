import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import { Pagination } from '@/core/types'
import { createInjectionState, toReactive } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import type { TCoOwnership, TProject } from '../../../types'
import { useCoOwnershipQuery } from '../query/useCoOwnershipQuery'

const [useProvideCoOwnershipModule, useCoOwnershipModule] =
  createInjectionState(() => {
    const route = useRoute()
    const rows = ref<number[]>([])
    const form = ref<TCoOwnership>({})
    const filters = ref<ToQueryStringFilters>({
      param: '',
      dates: {
        created_at: [],
      },
      order_by: 'created_at,desc',
      project_id: route.params?.id ? [route.params?.id] : [],
    })

    const coOwnersCatalogFilters = ref<ToQueryStringFilters>({
      param: '',
      status_id: [1],
    })

    const moduleQueryParams = useToQueryString(toReactive(filters), {
      searchColumns: ['name'],
    })

    const coOwnersCatalogQueryParams = useToQueryString(
      toReactive(coOwnersCatalogFilters),
    )

    const modulePagination = usePagination()

    const coOwnershipQuery = useCoOwnershipQuery({
      modelId: computed(() => route.params?.id?.toString()),
      currentPage: modulePagination.currentPage,
      filters: {
        loadRecords: moduleQueryParams.queryParams,
        coOwnersCatalog: coOwnersCatalogQueryParams.queryParams,
      },
      enableQuery: {
        loadRecords: true,
        coOwnersCatalog: true,
      },
      handlers: {
        onLoadRecords: (pagination: Pagination) => {
          modulePagination.setPagination(pagination)
        },
      },
    })

    const { orderByOptions } = useOrderByFilter({
      columns: ['created_at'],
    })

    const onAdd = async (payload: TProject) => {
      await coOwnershipQuery.addRecord.mutateAsync(payload)
    }

    const onEdit = async (payload: TProject) => {
      await coOwnershipQuery.editRecord.mutateAsync(payload)
    }

    const tableData = computed(
      () => coOwnershipQuery.loadRecords.data?.value?.data,
    )

    const coOwnersCatalog = computed(
      () => coOwnershipQuery.coOwnersCatalog.data?.value,
    )

    const isLoading = computed(() => {
      return (
        coOwnershipQuery.coOwnersCatalog.isLoading.value ||
        coOwnershipQuery.addRecord.isLoading.value ||
        coOwnershipQuery.editRecord.isLoading.value
      )
    })

    return {
      form,
      isLoading,
      orderByOptions,
      tableData,
      rows,
      coOwnersCatalog,
      modulePagination: toReactive(modulePagination),
      onAdd,
      onEdit,
    }
  })

export { useCoOwnershipModule, useProvideCoOwnershipModule }
