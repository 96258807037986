import {
  ToQueryStringFilters,
  useDate,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import { useCatalogStore } from '@/core/store/catalogs'
import { Pagination, TImportFile, TRecordAction } from '@/core/types'
import { createInjectionState, toReactive } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import type { TCampaign } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'

const [useProvideModule, useModule] = createInjectionState(() => {
  const rows = ref<number[]>([])
  const form = ref<TCampaign>({})
  const filters = ref<ToQueryStringFilters>({
    param: '',
    dates: {
      created_at: [],
    },
    order_by: 'created_at,desc',
  })
  const store = useCatalogStore()
  const { catalogs } = storeToRefs(store)

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['name', 'media.name'],
    dateSettings: {
      enableFormat: true,
    },
  })

  const modulePagination = usePagination()

  const moduleQuery = useModuleQuery({
    currentPage: modulePagination.currentPage,
    filters: {
      loadRecords: moduleQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: true,
      referralsCatalog: true,
      socialNetworksCatalog: true,
    },
    handlers: {
      onLoadRecords: (pagination: Pagination) => {
        modulePagination.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at'],
  })

  const { zonedTimeToUtc } = useDate()
  const onAdd = async (payload: TCampaign) => {
    await moduleQuery.addRecord.mutateAsync({
      ...payload,
      initial_date: zonedTimeToUtc(new Date(payload?.initial_date ?? ''), {
        removeTimeZone: true,
      }),
      end_date: zonedTimeToUtc(new Date(payload?.end_date ?? ''), {
        removeTimeZone: true,
      }),
    })
  }

  const onEdit = async (payload: TCampaign) => {
    await moduleQuery.editRecord.mutateAsync({
      ...payload,
      initial_date: zonedTimeToUtc(new Date(payload?.initial_date ?? ''), {
        removeTimeZone: true,
      }),
      end_date: zonedTimeToUtc(new Date(payload?.end_date ?? ''), {
        removeTimeZone: true,
      }),
    })
  }

  const onDeleteMany = async () => {
    await moduleQuery.deleteRecords.mutateAsync(rows.value)
  }

  const onDelete = async (payload: TCampaign) => {
    await moduleQuery.deleteRecord.mutateAsync(payload)
  }

  const onExport = async () => {
    await moduleQuery.exportRecords()
  }

  const onImport = async (data: TImportFile) => {
    await moduleQuery.importRecords.mutateAsync(data)
  }

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
  }

  const onRequestCatalogs = async (payload: number[]) => {
    await moduleQuery.requestCatalogs.mutateAsync({
      ids: payload,
    })
  }

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)

  const projectsCatalog = computed(
    () => moduleQuery.projectsCatalog.data?.value,
  )

  const isLoading = computed(
    () =>
      moduleQuery.addRecord.isLoading.value ||
      moduleQuery.editRecord.isLoading.value ||
      moduleQuery.loadRecords.isLoading.value,
  )

  const referralsCatalog = computed(
    () => moduleQuery.referralsCatalog.data?.value,
  )

  const socialNetworksCatalog = computed(
    () => moduleQuery.socialNetworksCatalog.data?.value,
  )

  return {
    tableData,
    filters,
    orderByOptions,
    rows,
    form,
    isLoading,
    modulePagination: toReactive(modulePagination),
    catalogs,
    projectsCatalog,
    referralsCatalog,
    socialNetworksCatalog,
    onRequestCatalogs,
    onImport,
    onAction,
    onExport,
    onDelete,
    onDeleteMany,
    onEdit,
    onAdd,
  }
})

export { useModule, useProvideModule }
