import type {
  BaseServiceOptions,
  Pagination,
  TRecordAction,
} from '@/core/types'
import {
  type TCustomer,
  moduleServiceHandlers,
  customerCoOwnershipsHandlers,
  type TCustomers,
  serviceConfig,
  TCustomerProperties,
} from '../../..'
import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'

const { model, coOwnerships } = serviceConfig

export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, handlers, enableQuery, filters, modelId } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TCustomer>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const deleteRecord = useMutation<void, AxiosError, TCustomer>(
    (payload) => moduleServiceHandlers.deleteRecord(payload),
    {
      onSuccess: (_, customer) => serviceHandlers.deleteRecord(customer),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const deleteRecords = useMutation<void, AxiosError, Array<number>>(
    (payload) => moduleServiceHandlers.deleteRecords({ ids: payload }),
    {
      onSuccess: (_, ids) => serviceHandlers.deleteRecords(ids),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const editRecord = useMutation<TCustomer, AxiosError, TCustomer>(
    (payload: TCustomer) =>
      moduleServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TCustomer, AxiosError, TCustomer>(
    (payload: TCustomer) => moduleServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TCustomer, AxiosError, TRecordAction>(
    (payload: TRecordAction) => moduleServiceHandlers.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TCustomers, AxiosError>(
    [model, currentPage, filters?.loadRecords],
    () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
      onSuccess: (data) => {
        if (data.meta) {
          handlers?.onLoadRecords?.(data.meta ?? defaultPagination)
        }
      },
    },
  )

  const loadRecord = useQuery<TCustomer, AxiosError>(
    [model, modelId],
    ({ queryKey }) =>
      moduleServiceHandlers.loadRecord({
        id: queryKey[1] as number,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecord,
      onError: (error) => errorHttpNotification(error),
      onSuccess: (payload) => {
        options.handlers?.onLoadRecord?.(payload)
      },
    },
  )

  const loadCoOwnershipsRecords = useQuery<TCustomerProperties, AxiosError>(
    [coOwnerships.model, currentPage, filters?.loadRecords],
    () =>
      customerCoOwnershipsHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
        modelId,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadCoOwnershipsRecords,
      onError: (error) => errorHttpNotification(error),
    },
  )

  return {
    loadRecords,
    addRecord,
    editRecord,
    deleteRecords,
    deleteRecord,
    actionRecord,
    loadRecord,
    loadCoOwnershipsRecords,
  }
}
