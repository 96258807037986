import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import { Pagination, TRecordAction } from '@/core/types'
import { createInjectionState, toReactive } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import type { TProject } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'

const [useProvideModule, useModule] = createInjectionState(() => {
  const rows = ref<number[]>([])
  const form = ref<TProject>({})
  const filters = ref<ToQueryStringFilters>({
    param: '',
    dates: {
      created_at: [],
    },
    order_by: 'created_at,desc',
  })
  const router = useRouter()
  const route = useRoute()

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['name'],
    dateSettings: {
      enableFormat: true,
    },
  })

  const modulePagination = usePagination()

  const moduleQuery = useModuleQuery({
    modelId: computed(() => route.params?.id?.toString()),
    currentPage: modulePagination.currentPage,
    filters: {
      loadRecords: moduleQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: computed(() => route.name !== 'projectsDetails'),
      loadRecord: computed(() => route.name === 'projectsDetails'),
    },
    handlers: {
      onLoadRecords: (pagination: Pagination) => {
        modulePagination.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at'],
  })

  const onAdd = async (payload: TProject) => {
    await moduleQuery.addRecord.mutateAsync(payload).then((project) => {
      onRedirect(project)
    })
  }

  const onEdit = async (payload: TProject) => {
    await moduleQuery.editRecord.mutateAsync(payload)
  }

  const onDeleteMany = async () => {
    await moduleQuery.deleteRecords.mutateAsync(rows.value)
  }

  const onDelete = async (payload: TProject) => {
    await moduleQuery.deleteRecord.mutateAsync(payload)
  }

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
  }

  const onRedirect = (payload: TProject) => {
    router.replace({ name: 'projectsDetails', params: { id: payload.id } })
  }

  const model = computed(() => moduleQuery.loadRecord.data.value)

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)

  const isLoading = computed(() => {
    if (route.name === 'projectsDetails') {
      return moduleQuery.loadRecord.isLoading.value
    }

    return (
      moduleQuery.addRecord.isLoading.value ||
      moduleQuery.editRecord.isLoading.value ||
      moduleQuery.loadRecords.isLoading.value
    )
  })

  return {
    tableData,
    filters,
    orderByOptions,
    rows,
    form,
    isLoading,
    modulePagination: toReactive(modulePagination),
    model,
    onAction,
    onDelete,
    onDeleteMany,
    onEdit,
    onAdd,
  }
})

export { useModule, useProvideModule }
