import { useNotification } from '@/core'
import type { BaseServiceOptions, Pagination } from '@/core/types'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { MaybeRef } from 'vue'
import { type TCustomer, uploadServiceHandlers } from '../../..'

export type TUploadBody = {
  file: File
  customerId?: MaybeRef<number | string>
  modelId?: MaybeRef<number | string>
  categoryId?: MaybeRef<number | string>
}

type BaseServiceOverrideOptions = {
  handlers?: {
    onUploadingFileSuccess?: (payload: TUploadBody) => void
    onUploadingFileError?: (payload: TUploadBody) => void
  }
}

export const useUploadQuery = (
  options: BaseServiceOptions<Pagination> & BaseServiceOverrideOptions,
) => {
  const { enableQuery, handlers, modelId } = options

  const { errorHttpNotification } = useNotification()

  const onFormatDocumentCategories = (customer?: TCustomer) => {
    const categories = customer?.categories

    if (!categories?.length) return []

    return categories.map((category) => {
      const document = isUploadedFile(customer, category?.category_id)

      return {
        ...category,
        is_uploaded_document: !!document,
        file_name: document?.name,
      }
    })
  }

  const isUploadedFile = (customer?: TCustomer, categoryId?: number) =>
    customer?.documents?.find(
      (document) => document?.category_id === categoryId,
    )

  const uploadFile = useMutation<void, AxiosError, TUploadBody>(
    (payload: TUploadBody) => {
      const uploadForm = new FormData()
      uploadForm.append('file', payload.file)
      uploadForm.append('category_id', payload.categoryId?.toString() ?? '')

      return uploadServiceHandlers.uploadDocument(
        {
          ...payload,
          modelId: payload.customerId,
        },
        uploadForm,
      )
    },
    {
      onSuccess: (_, payload) => {
        handlers?.onUploadingFileSuccess?.(payload)
      },
      onError: (error, payload) => {
        handlers?.onUploadingFileError?.(payload)

        return errorHttpNotification(error)
      },
    },
  )

  const loadFilesByCustomer = useQuery<TCustomer[], AxiosError>(
    ['files-to-upload'],
    () =>
      uploadServiceHandlers.loadFilesByCustomer({
        modelId,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadFilesByCustomer,
      onError: (error) => errorHttpNotification(error),
      select: (data) =>
        data.map((customer) => ({
          ...customer,
          documents_uploaded: customer.documents?.length,
          documents_to_upload: customer.categories?.length,
          categories: onFormatDocumentCategories(customer),
        })),
    },
  )

  return {
    uploadFile,
    loadFilesByCustomer,
  }
}
