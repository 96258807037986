import {
  ToQueryStringFilters,
  useDate,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import { Pagination, TRecordAction } from '@/core/types'
import { TSale } from '@/modules/sales'
import { createInjectionState, toReactive } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import type { TQuote } from '../../../types'
import { useCatalogQuery } from '../query/useCatalogQuery'
import { useQuoteQuery } from '../query/useQuoteQuery'

const [useProvideQuoteModule, useQuoteModule] = createInjectionState(() => {
  const route = useRoute()
  const { zonedTimeToUtc } = useDate()
  const rows = ref<number[]>([])
  const form = ref<TQuote>({})
  const router = useRouter()
  const filters = ref<ToQueryStringFilters>({
    param: '',
    dates: {
      created_at: [],
    },
    order_by: 'created_at,desc',
  })

  const lotsFilters = ref<ToQueryStringFilters>({
    param: '',
    project_id: [],
    sold: [0],
  })

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['id'],
    dateSettings: {
      enableFormat: true,
    },
  })

  const lotsQueryParams = useToQueryString(toReactive(lotsFilters), {
    searchColumns: ['project_id'],
  })

  const modulePagination = usePagination()

  const quoteQuery = useQuoteQuery({
    modelId: computed(() => route.params?.id?.toString()),
    currentPage: modulePagination.currentPage,
    filters: {
      loadRecords: moduleQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: true,
    },
    handlers: {
      onLoadRecords: (pagination: Pagination) => {
        modulePagination.setPagination(pagination)
      },
    },
  })

  const catalogQuery = useCatalogQuery({
    modelId: {
      projectId: computed(() => lotsFilters.value.project_id?.[0] ?? 1),
    },
    filters: {
      lotsCatalog: lotsQueryParams.queryParams,
    },
    enableQuery: {
      advisorsCatalog: false,
      campaignsCatalog: false,
      originsCatalog: false,
      statusesCatalog: false,
      lotsCatalog: computed(() => !!lotsQueryParams.params.value.length),
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at'],
  })

  const onAdd = async (payload: TQuote) => {
    await quoteQuery.addRecord.mutateAsync({
      ...payload,
      ...(payload?.initial_down_deposit_date
        ? {
            initial_down_deposit_date: zonedTimeToUtc(
              new Date(payload?.initial_down_deposit_date),
              {
                removeTimeZone: true,
              },
            ),
          }
        : {}),
      ...(payload?.monthly_deposit_date
        ? {
            monthly_deposit_date: zonedTimeToUtc(
              new Date(payload?.monthly_deposit_date),
              {
                removeTimeZone: true,
              },
            ),
          }
        : {}),
    })
  }

  const onRedirect = (payload: TSale) => {
    router.replace({
      name: 'salesAssignProjectDetail',
      params: { id: payload.id },
    })
  }
  const onEdit = async (payload: TQuote) => {
    await quoteQuery.editRecord.mutateAsync(payload)
  }

  const onAction = async (action: TRecordAction) => {
    await quoteQuery.actionRecord.mutateAsync(action).then((sale) => {
      action.action == 'deriving-from' ? onRedirect(sale) : null
    })
  }

  const onDelete = async (payload: TQuote) => {
    await quoteQuery.deleteRecord.mutateAsync(payload)
  }

  const tableData = computed(() => quoteQuery.loadRecords.data?.value?.data)

  const isLoading = computed(() => {
    return (
      quoteQuery.addRecord.isLoading.value ||
      quoteQuery.editRecord.isLoading.value ||
      quoteQuery.loadRecords.isLoading.value ||
      quoteQuery.loadRecords.isFetching.value
    )
  })

  const projectsCatalog = computed(
    () => catalogQuery.projectsCatalog.data?.value,
  )

  const lotsCatalog = computed(() => catalogQuery.lotsCatalog.data?.value?.data)

  return {
    isLoading,
    form,
    quoteQuery,
    orderByOptions,
    tableData,
    rows,
    projectsCatalog,
    lotsCatalog,
    modulePagination: toReactive(modulePagination),
    lotsFilters,
    filters,
    onAdd,
    onEdit,
    onAction,
    onDelete,
  }
})

export { useProvideQuoteModule, useQuoteModule }
