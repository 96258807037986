import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import { Pagination, TRecordAction } from '@/core/types'
import { createInjectionState, toReactive } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import type { TSale } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'

const [useProvideModule, useModule] = createInjectionState(() => {
  const rows = ref<number[]>([])
  const form = ref<TSale>({})
  const router = useRouter()
  const route = useRoute()
  const filters = ref<ToQueryStringFilters>({
    param: '',
    dates: {
      sale_date: [],
    },
    order_by: 'sale_date,desc',
    by_status: [],
  })

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['project_name', 'customer_name'],
    dateSettings: {
      enableFormat: true,
    },
  })

  const modulePagination = usePagination()

  const moduleQuery = useModuleQuery({
    currentPage: modulePagination.currentPage,
    modelId: computed(() => route.params?.id?.toString()),
    filters: {
      loadRecords: moduleQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: true,
      loadRecord: false,
    },
    handlers: {
      onLoadRecords: (pagination: Pagination) => {
        modulePagination.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['sale_date'],
  })

  const onAdd = async (payload: TSale) => {
    await moduleQuery.addRecord.mutateAsync(payload).then((sale) => {
      onRedirect(sale)
    })
  }

  const onRedirect = (payload: TSale) => {
    router.replace({ name: 'salesAssignCustomer', params: { id: payload.id } })
  }

  const onEdit = async (payload: TSale) => {
    await moduleQuery.editRecord
      .mutateAsync(payload)
      .then((sale) => onRedirect(sale))
  }

  const onDeleteMany = async () => {
    await moduleQuery.deleteRecords.mutateAsync(rows.value)
  }

  const onDelete = async (payload: TSale) => {
    await moduleQuery.deleteRecord.mutateAsync(payload)
  }

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
  }

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)

  const isLoading = computed(
    () =>
      moduleQuery.addRecord.isLoading.value ||
      moduleQuery.editRecord.isLoading.value ||
      moduleQuery.loadRecords.isLoading.value,
  )

  const model = computed(() => moduleQuery.loadRecord.data.value)

  return {
    tableData,
    filters,
    orderByOptions,
    rows,
    form,
    isLoading,
    modulePagination: toReactive(modulePagination),
    model,
    onAction,
    onDelete,
    onDeleteMany,
    onEdit,
    onAdd,
  }
})

export { useModule, useProvideModule }
