export const en = {
  login: {
    TITLE: '¡Welcome!',
    SUBTITLE: 'Please enter your credentials',
    EMAIL: { TEXT: 'Email', PLACEHOLDER: 'Email address' },
    PASSWORD: { TEXT: 'Password', PLACEHOLDER: '**********' },
  },
  strings: {
    LOGOUT_SUCCESS: 'You have successfully logged out',
    HOME: 'Home',
    DASHBOARD: 'Dashboard',
    USERS: 'Users',
    ARTICLES: 'Materials',
    INVENTORIES: 'Existence Inquiry',
    CATEGORIES: 'Categories',
    TYPES: 'Types',
    METRICS: 'Metrics',
    PURCHASE_ORDERS: 'Purchase Orders',
    WELCOME_USER: 'Welcome {attribute}',
    SUPPLIERS: 'Suppliers',
    ARE_YOU_SURE: {
      TITLE: '{attribute}',
      TEXT: 'Are you sure you want to continue? This action cannot be undone.',
    },
    MODAL: {
      ADD: 'Add {attribute}',
      EDIT: 'Edit {attribute}',
      NEW: 'New {attribute}',
    },
    SOMETHING_WENT_WRONG: 'Oops! Something went wrong',
    CRUD_HELPER: '{attribute} successfully',
    SUBTITLE_SUMMARY: 'A summary of your {attribute}',
    SEARCH: 'Search',
    ORDER_BY: 'Order By',
    NO_DATA_TO_DISPLAY: 'No data to display',
    LOCATIONS: 'Locations',
    WAREHOUSE: 'Warehouse',
    ADVISORS: 'Advisors',
    CRM: 'CRM',
    CXP: 'CXP',
    CXC: 'CXC',
    MANAGEMENT_OF: 'Management of {attribute}',
    ADVISOR: 'Advisors',
    CAMPAIGNS: 'Campaigns',
    LEADS: 'Leads',
  },
  filters: {
    TITLE: 'Filters',
  },
  actions: {
    LOGOUT: 'Log out',
    CANCELLED: 'Cancel',
    CLOSE: 'Close',
    ADD: 'Add',
    ACCEPT: 'Accept',
    SAVE: 'Save',
    EDIT: 'Edit',
    DELETE: 'Delete',
    EXPORT: 'Export',
    IMPORT: 'Import',
    CHOOSE: 'Choose',
    ACTIVATE: 'Activate',
    INACTIVATE: 'Deactivate',
    DETAIL: 'Detail',
    ADDED: 'Saved',
    UPDATED: 'Updated',
    DOWNLOADED: 'Downloaded',
    DELETED: 'Deleted',
    SEE: 'View',
    NEW: 'New {attribute}',
    APPROVED: 'Approve',
    PRINT: 'Print',
    ADD_ARTICLE: 'Add Material',
    TYPE_MOVEMENT: 'T.M',
  },
  settings: {
    TITLE: 'Settings',
    THEME: 'Theme',
    THEME_MODE: { LIGHT: 'Light', DARK: 'Dark' },
    LANG: 'Language',
  },
  locale: {
    ES: 'Spanish',
    EN: 'English',
  },
  users: {
    MODEL: 'User | Users',
    PASSWORD: 'Password',
    PASSWORD_CONFIRM: 'Confirm Password',
  },
  suppliers: {
    MODEL: 'Supplier | Suppliers',
  },
  articles: {
    MODEL: 'Material | Materials',
  },
  categories: {
    MODEL: 'Category | Categories',
  },
  types: {
    MODEL: 'Type | Types',
  },
  'measurement-units': {
    MODEL: 'Unit of Measure | Units of Measure',
  },
  warehouses: {
    MODEL: 'Warehouse | Warehouses',
  },
  'purchase-orders': {
    MODEL: 'Purchase Order | Purchase Orders',
  },
  'purchase-order-details': {
    MODEL: 'Purchase Order Detail | Purchase Order Details',
  },
  columns: {
    INDICATOR: 'Indicator',
    USER: 'User',
    PRODUCT: 'Product',
    NAME: 'Name',
    PRICE: 'Price',
    STATUS: 'Status',
    CREATED_AT: 'Date Created',
    SUPPLIER: 'Supplier',
    INIT_DATE: 'Placement Date',
    ENTRY_DATE: 'Arrival Date',
    TOTAL: 'Total',
    CURRENCY: 'Currency',
    ACTIONS: 'Actions',
    FOLIO: 'Folio',
    LAST_NAME: 'Last Name',
    EMAIL: 'Email',
    ROL: 'Role',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    PART_NUMBER: 'Part Number',
    CATEGORY: 'Category',
    TYPE: 'Type',
    METRIC: 'U.M',
    ID: 'ID',
    ARTICLE_NAME: 'Material Name',
    ARTICLE: 'Material',
    QUANTITY: 'Quantity',
    UNIT_PRICE: 'Unit Price',
    TAX: 'Tax',
    INITIAL_QUANTITY: 'C.I',
    AMOUNT: 'C',
    FINAL_QUANTITY: 'C.F',
    INITIAL_QUANTITY_TEXT: 'Initial amount',
    AMOUNT_TEXT: 'Amount',
    FINAL_QUANTITY_TEXT: 'Final Amount',
    RFC: 'RFC',
    CONDITIONS: 'Conditions',
    PHONE: 'Phone',
    MEDIA: 'Media',
    SOCIAL_NETWORK: 'Social network | Social networks',
    INITIAL_DATE: 'Initial date',
    END_DATE: 'End date',
    COST: 'Cost',
    EXPECTED_LEADS: 'Expected leads',
    ADVISOR: 'Advisor',
  },
  status: {
    ACTIVED: 'Active',
    DELETED: 'Deleted',
    INACTIVED: 'Inactive',
    REQUESTED: 'In Process',
    PENDING: 'Pending',
    DONE: 'Completed',
    DRAFTED: 'Draft',
    CANCELLED: 'Cancelled',
    IN_PROCESS: 'In Process',
    RECEIVED: 'Received',
    APPROVED: 'Approved',
  },
  validation: {
    ACCEPTED: 'The {attribute} must be accepted.',
    ACCEPTED_IF: 'The {attribute} must be accepted when {other} is {value}.',
    ACTIVE_URL: 'The {attribute} is not a valid URL.',
    AFTER: 'The {attribute} must be a date after {date}.',
    AFTER_OR_EQUAL: 'The {attribute} must be a date after or equal to {date}.',
    ALPHA: 'The {attribute} must only contain letters.',
    ALPHA_DASH:
      'The {attribute} must only contain letters, numbers, dashes and underscores.',
    ALPHA_NUM: 'The {attribute} must only contain letters and numbers.',
    ARRAY: 'The {attribute} must be an array.',
    BEFORE: 'The {attribute} must be a date before {date}.',
    BEFORE_OR_EQUAL:
      'The {attribute} must be a date before or equal to {date}.',
    BETWEEN: {
      NUMERIC: 'The {attribute} must be between {min} and {max}.',
      FILE: 'The {attribute} must be between {min} and {max} kilobytes.',
      STRING: 'The {attribute} must be between {min} and {max} characters.',
      ARRAY: 'The {attribute} must have between {min} and {max} items.',
    },
    BOOLEAN: 'The {attribute} field must be true or false.',
    CONFIRMED: 'The {attribute} confirmation does not match.',
    CURRENT_PASSWORD: 'The password is incorrect.',
    DATE: 'The {attribute} is not a valid date.',
    DATE_EQUALS: 'The {attribute} must be a date equal to {date}.',
    DATE_FORMAT: 'The {attribute} does not match the format {format}.',
    DECLINED: 'The {attribute} must be declined.',
    DECLINED_IF: 'The {attribute} must be declined when {other} is {value}.',
    DIFFERENT: 'The {attribute} and {other} must be different.',
    DIGITS: 'The {attribute} must be {digits} digits.',
    DIGITS_BETWEEN: 'The {attribute} must be between {min} and {max} digits.',
    DIMENSIONS: 'The {attribute} has invalid image dimensions.',
    DISTINCT: 'The {attribute} field has a duplicate value.',
    EMAIL: 'The {attribute} must be a valid email address.',
    ENDS_WITH: 'The {attribute} must end with one of the following: {values}.',
    ENUM: 'The selected {attribute} is invalid.',
    EXISTS: 'The selected {attribute} is invalid.',
    FILE: 'The {attribute} must be a file.',
    FILLED: 'The {attribute} field must have a value.',
    GT: {
      NUMERIC: 'The {attribute} must be greater than {value}.',
      FILE: 'The {attribute} must be greater than {value} kilobytes.',
      STRING: 'The {attribute} must be greater than {value} characters.',
      ARRAY: 'The {attribute} must have more than {value} items.',
    },
    GTE: {
      NUMERIC: 'The {attribute} must be greater than or equal to {value}.',
      FILE: 'The {attribute} must be greater than or equal to {value} kilobytes.',
      STRING:
        'The {attribute} must be greater than or equal to {value} characters.',
      ARRAY: 'The {attribute} must have {value} items or more.',
    },
    IMAGE: 'The {attribute} must be an image.',
    IN: 'The selected {attribute} is invalid.',
    IN_ARRAY: 'The {attribute} field does not exist in {other}.',
    INTEGER: 'The {attribute} must be an integer.',
    IP: 'The {attribute} must be a valid IP address.',
    IPV4: 'The {attribute} must be a valid IPv4 address.',
    IPV6: 'The {attribute} must be a valid IPv6 address.',
    JSON: 'The {attribute} must be a valid JSON string.',
    LT: {
      NUMERIC: 'The {attribute} must be less than {value}.',
      FILE: 'The {attribute} must be less than {value} kilobytes.',
      STRING: 'The {attribute} must be less than {value} characters.',
      ARRAY: 'The {attribute} must have less than {value} items.',
    },
    LTE: {
      NUMERIC: 'The {attribute} must be less than or equal to {value}.',
      FILE: 'The {attribute} must be less than or equal to {value} kilobytes.',
      STRING:
        'The {attribute} must be less than or equal to {value} characters.',
      ARRAY: 'The {attribute} must not have more than {value} items.',
    },
    MAC_ADDRESS: 'The {attribute} must be a valid MAC address.',
    MAX: {
      NUMERIC: 'The {attribute} must not be greater than {max}.',
      FILE: 'The {attribute} must not be greater than {max} kilobytes.',
      STRING: 'The {attribute} must not be greater than {max} characters.',
      ARRAY: 'The {attribute} must not have more than {max} items.',
    },
    MIMES: 'The {attribute} must be a file of type: {values}.',
    MIMETYPES: 'The {attribute} must be a file of type: {values}.',
    MIN: {
      NUMERIC: 'The {attribute} must be at least {min}.',
      FILE: 'The {attribute} must be at least {min} kilobytes.',
      STRING: 'The {attribute} must be at least {min} characters.',
      ARRAY: 'The {attribute} must have at least {min} items.',
    },
    MULTIPLE_OF: 'The {attribute} must be a multiple of {value}.',
    NOT_IN: 'The selected {attribute} is invalid.',
    NOT_REGEX: 'The {attribute} format is invalid.',
    NUMERIC: 'The {attribute} must be a number.',
    PASSWORD: 'The password is incorrect.',
    PRESENT: 'The {attribute} field must be present.',
    PROHIBITED: 'The {attribute} field is prohibited.',
    PROHIBITED_IF:
      'The {attribute} field is prohibited when {other} is {value}.',
    PROHIBITED_UNLESS:
      'The {attribute} field is prohibited unless {other} is in {values}.',
    PROHIBITS: 'The {attribute} field prohibits {other} from being present.',
    REGEX: 'The {attribute} format is invalid.',
    REQUIRED: 'The {attribute} field is required.',
    REQUIRED_ARRAY_KEYS:
      'The {attribute} field must contain entries for: {values}.',
    REQUIRED_IF: 'The {attribute} field is required when {other} is {value}.',
    REQUIRED_UNLESS:
      'The {attribute} field is required unless {other} is in {values}.',
    REQUIRED_WITH:
      'The {attribute} field is required when {values} is present.',
    REQUIRED_WITH_ALL:
      'The {attribute} field is required when {values} are present.',
    REQUIRED_WITHOUT:
      'The {attribute} field is required when {values} is not present.',
    REQUIRED_WITHOUT_ALL:
      'The {attribute} field is required when none of {values} are present.',
    SAME: 'The {attribute} and {other} must match.',
    SIZE: {
      NUMERIC: 'The {attribute} must be {size}.',
      FILE: 'The {attribute} must be {size} kilobytes.',
      STRING: 'The {attribute} must be {size} characters.',
      ARRAY: 'The {attribute} must contain {size} items.',
    },
    STARTS_WITH:
      'The {attribute} must start with one of the following: {values}.',
    STRING: 'The {attribute} must be a string.',
    TIMEZONE: 'The {attribute} must be a valid timezone.',
    UNIQUE: 'The {attribute} has already been taken.',
    UPLOADED: 'The {attribute} failed to upload.',
    URL: 'The {attribute} must be a valid URL.',
    UUID: 'The {attribute} must be a valid UUID.',
    PASSWORD_EQUALS: 'Passwords should be equals.',
    ATTRIBUTES: {},
  },
  advisors: {
    MODEL: 'Advisor | Advisors',
    TYPE: 'Advisor type',
  },
  campaigns: {
    MODEL: 'Campaign | Campaigns',
  },
  leads: {
    MODEL: 'Lead | Leads',
  },
}
