import { authRoles } from '@/core'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN, ADVISOR } = authRoles

const SocialNetworks = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/index.vue')

export const socialNetworksModuleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/social-networks',
    name: 'social-networks',
    component: SocialNetworks,
    meta: {
      title: 'Redes Sociales',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
]
