import { DataWithPagination } from '@/core/types'
import { createCrudEndpoints } from '@/core/utils'
import { TMedia } from '.'

export type TMedias = DataWithPagination<TMedia>

export const serviceConfig = {
  model: 'social-networks',
  version: 'v1',
}

const moduleServiceHandlers = createCrudEndpoints<TMedias, TMedia>(
  serviceConfig.model,
  serviceConfig.version,
)

export { moduleServiceHandlers }
