import { DataWithPagination, TBaseProject } from '@/core/types'
import {
  createCatalogEndpoint,
  createCrudEndpoints,
  createNestedCrudEndpoints,
} from '@/core/utils'
import { TCoOwners } from '../co-owners'
import { TCoOwnership, TLot, TProject } from './types'

export type TProjects = DataWithPagination<TBaseProject>

export type TCoOwnerships = DataWithPagination<TCoOwnership>

export type TLots = DataWithPagination<TLot>

export const serviceConfig = {
  model: 'projects',
  version: 'v1',
  coOwnerships: {
    model: 'co-ownerships',
    version: 'v1',
  },
  coOwners: {
    model: 'co-owners',
    version: 'v1',
  },
  lots: {
    model: 'lots',
    version: 'v1',
  },
}

const { coOwnerships, coOwners, lots } = serviceConfig

const moduleServiceHandlers = createCrudEndpoints<TProjects, TProject>(
  serviceConfig.model,
  serviceConfig.version,
)

const coOwnersCatalogService = createCatalogEndpoint<TCoOwners>(
  coOwners.model,
  coOwners.version,
)

const coOwnershipNestedService = createNestedCrudEndpoints<
  TCoOwnerships,
  TCoOwnership
>(serviceConfig.model, coOwnerships.model, coOwnerships.version)

const lotsService = createNestedCrudEndpoints<TLots, TLot>(
  serviceConfig.model,
  lots.model,
  lots.version,
)

export {
  coOwnersCatalogService,
  coOwnershipNestedService,
  lotsService,
  moduleServiceHandlers,
}
