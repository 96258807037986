import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import type {
  BaseServiceOptions,
  Pagination,
  TRecordAction,
} from '@/core/types'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import {
  type TMedia,
  type TMedias,
  moduleServiceHandlers,
  serviceConfig,
} from '../../..'

const { model } = serviceConfig

export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, handlers, enableQuery, filters } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TMedia>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const editRecord = useMutation<TMedia, AxiosError, TMedia>(
    (payload: TMedia) => moduleServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TMedia, AxiosError, TMedia>(
    (payload: TMedia) => moduleServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TMedia, AxiosError, TRecordAction>(
    (payload: TRecordAction) => moduleServiceHandlers.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const deleteRecord = useMutation<void, AxiosError, TMedia>(
    (payload) => moduleServiceHandlers.deleteRecord(payload),
    {
      onSuccess: (_, sale) => serviceHandlers.deleteRecord(sale),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TMedias, AxiosError>(
    [model, currentPage, filters?.loadRecords],
    () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
      onSuccess: (data) => {
        if (data.meta) {
          handlers?.onLoadRecords?.(data.meta ?? defaultPagination)
        }
      },
    },
  )

  return {
    loadRecords,
    addRecord,
    editRecord,
    actionRecord,
    deleteRecord,
  }
}
