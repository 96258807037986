import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import type { BaseServiceOptions, Pagination } from '@/core/types'
import { TCoOwners } from '@/modules/co-owners'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { toValue } from 'vue'
import {
  type TCoOwnership,
  type TCoOwnerships,
  coOwnersCatalogService,
  coOwnershipNestedService,
  serviceConfig,
} from '../../..'

const { coOwnerships, coOwners } = serviceConfig

export const useCoOwnershipQuery = (
  options: BaseServiceOptions<Pagination>,
) => {
  const { currentPage, handlers, enableQuery, filters, modelId } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TCoOwnership>({
    model: coOwnerships.model,
    currentPage: toValue(currentPage) ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const editRecord = useMutation<TCoOwnership, AxiosError, TCoOwnership>(
    (payload: TCoOwnership) =>
      coOwnershipNestedService.editRecord(payload, {
        modelId,
        modelChildId: payload.id,
      }),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TCoOwnership, AxiosError, TCoOwnership>(
    (payload: TCoOwnership) =>
      coOwnershipNestedService.addRecord(payload, { modelId }),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TCoOwnerships, AxiosError>(
    [coOwnerships.model, currentPage, filters?.loadRecords],
    () =>
      coOwnershipNestedService.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
        modelId,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
      onSuccess: (data) => {
        if (data.meta) {
          handlers?.onLoadRecords?.(data.meta ?? defaultPagination)
        }
      },
    },
  )

  const coOwnersCatalog = useQuery<TCoOwners, AxiosError>(
    [coOwners.model, coOwners.version, filters?.coOwnersCatalog],
    () =>
      coOwnersCatalogService.loadCatalog({
        dynamicFilters: filters?.coOwnersCatalog,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.campaignsCatalog,
      onError: (error) => errorHttpNotification(error),
    },
  )

  return {
    loadRecords,
    addRecord,
    editRecord,
    coOwnersCatalog,
  }
}
