import { authRoles } from '@/core'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN, ADVISOR } = authRoles

const Customers = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/index.vue')

const CustomerDetail = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/detail.vue')

const CustomerBackgrounds = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/backgrounds.vue')

export const customersModuleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/customers',
    name: 'customers',
    component: Customers,
    meta: {
      title: 'Clientes',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: 'customers/:id/detail',
    name: 'customerDetail',
    component: CustomerDetail,
    meta: {
      title: 'Detalle Cliente',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: 'customers/:id/documents',
    name: 'customerDetailDocuments',
    component: CustomerDetail,
    meta: {
      title: 'Documentos Cliente',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: 'customers/:id/backgrounds',
    name: 'customerBackgrounds',
    component: CustomerBackgrounds,
    meta: {
      title: 'Antecedentes Cliente',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
]
