import { authRoles } from '@/core'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN, ADVISOR } = authRoles

const BusinessNames = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/index.vue')

export const businessNamesModuleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/business-names',
    name: 'business-names',
    component: BusinessNames,
    meta: {
      title: 'Razones Sociales',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
]
