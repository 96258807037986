import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import { Pagination, TRecordAction } from '@/core/types'
import { createInjectionState, toReactive } from '@vueuse/core'
import { computed, ref } from 'vue'
import type { TMedia } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'

const [useProvideModule, useModule] = createInjectionState(() => {
  const rows = ref<number[]>([])
  const form = ref<TMedia>({})
  const filters = ref<ToQueryStringFilters>({
    param: '',
    dates: {
      created_at: [],
    },
    order_by: 'created_at,desc',
  })

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    searchColumns: ['name'],
    dateSettings: {
      enableFormat: true,
    },
  })

  const modulePagination = usePagination()

  const moduleQuery = useModuleQuery({
    currentPage: modulePagination.currentPage,
    filters: {
      loadRecords: moduleQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: true,
    },
    handlers: {
      onLoadRecords: (pagination: Pagination) => {
        modulePagination.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at'],
  })

  const onAdd = async (payload: TMedia) => {
    await moduleQuery.addRecord.mutateAsync(payload)
  }

  const onEdit = async (payload: TMedia) => {
    await moduleQuery.editRecord.mutateAsync(payload)
  }

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
  }

  const onDelete = async (payload: TMedia) => {
    await moduleQuery.deleteRecord.mutateAsync(payload)
  }

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)

  const isLoading = computed(
    () =>
      moduleQuery.addRecord.isLoading.value ||
      moduleQuery.editRecord.isLoading.value ||
      moduleQuery.loadRecords.isLoading.value,
  )

  return {
    tableData,
    filters,
    orderByOptions,
    rows,
    form,
    isLoading,
    modulePagination: toReactive(modulePagination),
    onAction,
    onEdit,
    onAdd,
    onDelete,
  }
})

export { useModule, useProvideModule }
