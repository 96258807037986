import { format } from 'date-fns-tz'
import { useI18n } from 'vue-i18n'
import { months, shortMonths } from '../constants'

type ManipulateDateParams = {
  days?: number
  months?: number
  hours?: number
  minutes?: number
  years?: number
}

type TZoneTimeOptions = {
  removeTimeZone?: boolean
}

type ManipulateDate = {
  date: string | Date
  operation: 'add' | 'subtract'
  params: ManipulateDateParams
}

type FormattedWithNameOptions = {
  separator?: [string?, string?]
  fullMonth?: boolean
  withHours?: boolean
}

export const useDate = () => {
  const { locale } = useI18n()

  const getCurrentDate = () => {
    const lang = locale.value as 'es' | 'en'
    const currentDate = new Date()
    const monthName = months[lang][currentDate.getMonth()]
    const day = currentDate.getDate()
    const year = currentDate.getFullYear()

    const formattedDate = `${monthName} ${day}, ${year}`
    return formattedDate
  }

  const getTimeFromDate = (date: string | Date) => {
    const dateTime = new Date(date)
    let hour = dateTime.getHours()
    const minutes = dateTime.getMinutes()
    const amOrPm = hour >= 12 ? 'pm' : 'am'

    if (hour > 12) {
      hour -= 12
    }

    return `${hour}:${
      !minutes ? '00' : minutes >= 10 ? minutes : '0' + minutes
    } ${amOrPm}`
  }

  const manipulateDate = (settings: ManipulateDate) => {
    const { date, params } = settings
    const { days = 0, hours = 0, minutes = 0, months = 0, years = 0 } = params

    const newDate = new Date(date)

    newDate.setDate(newDate.getDate() + days)
    newDate.setMonth(newDate.getMonth() + months)
    newDate.setHours(newDate.getHours() + hours)
    newDate.setMinutes(newDate.getMinutes() + minutes)
    newDate.setFullYear(newDate.getFullYear() + years)

    return newDate.toString()
  }

  const handleFormatDate = (date: Date | string, formatOptions?: string) =>
    date ? format(new Date(date), formatOptions ?? 'MM/dd/yyyy hh:mm a') : ''

  const zonedTimeToUtc = (date: Date, options?: TZoneTimeOptions) => {
    const utcDate = date.toISOString()

    if (options?.removeTimeZone) {
      return utcDate.replace('T', ' ').replace('Z', '').split('.')[0]
    }

    return date
  }

  const utcToZonedTime = (date: Date, options?: TZoneTimeOptions) => {
    const utcDate = date.toISOString()

    if (options?.removeTimeZone) {
      return utcDate.replace('Z', ' ').replace('T', '').split('.')[0]
    }

    return date
  }

  const formatDateWithNames = (
    date: Date,
    options?: FormattedWithNameOptions,
  ) => {
    const lang = locale.value as 'es' | 'en'
    const dayOfMonth = date.getDate()
    const month = options?.fullMonth
      ? months[lang][date.getMonth()]
      : shortMonths[lang][date.getMonth()]
    const year = date.getFullYear().toString()
    const time = getTimeFromDate(date)

    return `${dayOfMonth}${options?.separator?.[0] ?? ' '}${month}${
      options?.separator?.[1] ?? ' '
    }${year}${options?.withHours ? ` ${time}` : ''}`
  }

  return {
    formatDateWithNames,
    zonedTimeToUtc,
    getCurrentDate,
    getTimeFromDate,
    manipulateDate,
    handleFormatDate,
    utcToZonedTime,
  }
}
