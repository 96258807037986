import { DataWithPagination } from '@/core/types'
import { createCatalogEndpoint, createCrudEndpoints } from '@/core/utils'
import { TCoOwner } from '.'

export type TCoOwners = DataWithPagination<TCoOwner>

export const serviceConfig = {
  model: 'co-owners',
  version: 'v1',
  businessNames: {
    model: 'business-names',
    version: 'v1',
  },
}

const moduleServiceHandlers = createCrudEndpoints<TCoOwners, TCoOwner>(
  serviceConfig.model,
  serviceConfig.version,
)

const businessNamesService = createCatalogEndpoint<TCoOwner>(
  serviceConfig.businessNames.model,
  serviceConfig.businessNames.version,
)

export { businessNamesService, moduleServiceHandlers }
