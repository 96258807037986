import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import type {
  BaseServiceOptions,
  Pagination,
  TImportFile,
  TRecordAction,
} from '@/core/types'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import {
  type TLead,
  type TLeads,
  moduleServiceHandlers,
  serviceConfig,
} from '../../..'

const { model } = serviceConfig

export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, handlers, enableQuery, filters, modelId } = options
  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TLead>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const deleteRecord = useMutation<void, AxiosError, TLead>(
    (payload) => moduleServiceHandlers.deleteRecord(payload),
    {
      onSuccess: (_, lead) => serviceHandlers.deleteRecord(lead),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const deleteRecords = useMutation<void, AxiosError, Array<number>>(
    (payload) => moduleServiceHandlers.deleteRecords({ ids: payload }),
    {
      onSuccess: (_, ids) => serviceHandlers.deleteRecords(ids),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const editRecord = useMutation<TLead, AxiosError, TLead>(
    (payload: TLead) => moduleServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TLead, AxiosError, TLead>(
    (payload: TLead) => moduleServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TLead, AxiosError, TRecordAction>(
    (payload: TRecordAction) => moduleServiceHandlers.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const importRecords = useMutation<void, AxiosError, TImportFile>(
    (payload: TImportFile) => {
      const formData = new FormData()
      formData.append('importFile', payload.file)
      return moduleServiceHandlers.importRecords(formData)
    },
    {
      onSuccess: () => serviceHandlers.invalidateQueries(),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TLeads, AxiosError>(
    [model, currentPage, filters?.loadRecords],
    () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
      onSuccess: (data) => {
        if (data.meta) {
          handlers?.onLoadRecords?.(data.meta ?? defaultPagination)
        }
      },
    },
  )

  const loadRecord = useQuery<TLead, AxiosError>(
    [model, modelId],
    ({ queryKey }) =>
      moduleServiceHandlers.loadRecord({
        id: queryKey[1] as number,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecord,
      onError: (error) => errorHttpNotification(error),
      onSuccess: (payload) => {
        options.handlers?.onLoadRecord?.(payload)
      },
      select: (payload) => ({
        ...payload,
        advisor_id: payload.advisor?.id,
        status_id: payload.status?.id,
        campaign_id: payload.campaign?.id,
        origin_id: payload.origin?.id,
        project_ids: [...(payload.projects ?? [])]?.map(
          (project) => project.id,
        ),
        organic_id: payload.organic?.id,
      }),
    },
  )

  return {
    loadRecords,
    addRecord,
    editRecord,
    deleteRecords,
    deleteRecord,
    importRecords,
    actionRecord,
    loadRecord,
  }
}
