export const authRoles = {
  ADMIN: 'Admin',
  WAREHOUSEMAN: 'Warehouseman',
  ADVISOR: 'Advisor',
}

export const roles = [
  {
    label: authRoles.ADMIN,
  },
  {
    label: authRoles.WAREHOUSEMAN,
  },
]

export const warehouses = [
  {
    id: 1,
    name: 'General',
  },
]

export const defaultCharacters = {
  ENTRY: 'Entrada',
  OUTPUT: 'Salida',
}

export const statuses: Record<any, any> = {
  ACTIVED: 'status.ACTIVED',
  INACTIVED: 'status.INACTIVED',
  DRAFTED: 'status.DRAFTED',
  APPROVED: 'status.APPROVED',
  RECEIVED: 'status.RECEIVED',
  IN_PROCESS: 'status.IN_PROCESS',
  CANCELLED: 'status.CANCELLED',
  APPLIED: 'status.APPLIED',
  PENDING: 'status.PENDING',
  DELETED: 'status.DELETED',
  SENT: 'status.SENT',
  LOCKED: 'status.LOCKED',
  IN_CONTACT: 'status.IN_CONTACT',
  QUOTED: 'status.QUOTED',
  POSSIBLE_CLOSURE: 'status.POSSIBLE_CLOSURE',
  SALE_FINALIZED: 'status.SALE_FINALIZED',
  FALSE_CONTACT: 'status.FALSE_CONTACT',
  LOST: 'status.LOST',
  SOLD: 'status.SOLD',
  WINNER: 'status.WINNER',
  DERIVING_FROM: 'status.DERIVING_FROM',
  CONFIRMED: 'status.CONFIRMED',
  IN_REVIEW: 'status.IN_REVIEW',
}

export const defaultStatuses = {
  RECEIVED: 'RECEIVED',
  ACTIVED: 'ACTIVED',
  IN_PROCESS: 'IN_PROCESS',
  DRAFTED: 'DRAFTED',
  INACTIVED: 'INACTIVED',
  CANCELLED: 'CANCELLED',
  APPROVED: 'APPROVED',
  APPLIED: 'APPLIED',
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  IN_REVIEW: 'IN_REVIEW',
}

export const statusesList = Object.keys(defaultStatuses)

export const baseProps = {
  isOpen: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'add',
  },
}

export const months = {
  es: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
}

export const shortMonths = {
  es: [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ],
  en: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
}

export const daysOfWeek = {
  es: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ],
  en: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
}

export const defaultPagination = {
  current_page: 1,
  from: 1,
  last_page: 1,
  per_page: 10,
  total: 0,
}

export const recordActions = {
  actived: 'actived',
  active: 'active',
  inactived: 'inactived',
  inactive: 'inactive',
  cancel: 'cancel',
  approved: 'approved',
  applied: 'applied',
  apply: 'apply',
  added: 'added',
  substracted: 'substracted',
  winner: 'winner',
  'deriving-from': 'deriving-from',
  'in-review': 'in-review',
  confirmed: 'confirmed',
  cancelled: 'cancelled',
} as const

export const defaultOrder = {
  icon: 'pi-arrow-up',
  name: 'Fecha de creación',
  value: 'created_at,asc',
}

export const medias = {
  social_network: 9,
  referral: 8,
}

export const leadStatusIds = {}

export const categories = {
  appointment_category: 1,
}

export const availableCatalogs = {
  follow_up_types: 1,
  medias: 7,
  advisor_types: 10,
  origins: 13,
  organic: 14,
  campaign: 20,
}

export const statusesIds = {
  activated: 1,
  inactivated: 2,
  drafted: 3,
  approved: 4,
  received: 5,
  in_process: 6,
  cancelled: 7,
  applied: 8,
  pending: 9,
  deleted: 10,
  sent: 11,
  locked: 12,
  in_contact: 13,
  quoted: 14,
  possible_closure: 15,
  sale_finalized: 16,
  cancelled_leads: 17,
  false_contact: 18,
  lost: 19,
  winner: 20,
  deriving_from: 21,
  in_review: 22,
  confirmed: 23,
}

export const followUpTypesIds = {
  quotation_id: 6,
}
