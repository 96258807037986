import {
  ToQueryStringFilters,
  useOrderByFilter,
  usePagination,
  useToQueryString,
} from '@/core'
import { Pagination, TRecordAction } from '@/core/types'
import { createInjectionState, toReactive } from '@vueuse/core'
import { computed, ref } from 'vue'
import type { TCoOwner } from '../../../types'
import { useModuleQuery } from '../query/useModuleQuery'

const [useProvideModule, useModule] = createInjectionState(() => {
  const rows = ref<number[]>([])
  const form = ref<TCoOwner>({})
  const filters = ref<ToQueryStringFilters>({
    param: '',
    date: {
      created_at: [],
    },
  })

  const moduleQueryParams = useToQueryString(toReactive(filters), {
    dateSettings: {
      enableFormat: true,
    },
  })

  const modulePagination = usePagination()

  const moduleQuery = useModuleQuery({
    currentPage: modulePagination.currentPage,
    filters: {
      loadRecords: moduleQueryParams.queryParams,
    },
    enableQuery: {
      loadRecords: true,
    },
    handlers: {
      onLoadRecords: (pagination: Pagination) => {
        modulePagination.setPagination(pagination)
      },
    },
  })

  const { orderByOptions } = useOrderByFilter({
    columns: ['created_at', 'email', 'name', 'tax_name'],
  })

  const onAdd = async (payload: TCoOwner) => {
    await moduleQuery.addRecord.mutateAsync(payload)
  }

  const onEdit = async (payload: TCoOwner) => {
    await moduleQuery.editRecord.mutateAsync(payload)
  }

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
  }

  const tableData = computed(() => moduleQuery.loadRecords.data?.value?.data)

  const businessNamesCatalog = computed(
    () => moduleQuery.businessNamesCatalog.data?.value,
  )

  const isLoading = computed(
    () =>
      moduleQuery.addRecord.isLoading.value ||
      moduleQuery.editRecord.isLoading.value ||
      moduleQuery.loadRecords.isLoading.value,
  )

  return {
    tableData,
    filters,
    orderByOptions,
    rows,
    form,
    isLoading,
    modulePagination: toReactive(modulePagination),
    businessNamesCatalog,
    onAction,
    onEdit,
    onAdd,
  }
})

export { useModule, useProvideModule }
