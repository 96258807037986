import type { BaseServiceOptions, Pagination } from '@/core/types'
import {
  customerFilesHandlers,
  uploadServiceHandlers,
  serviceConfig,
  TCustomerFiles,
} from '../../..'
import { defaultPagination, useNotification } from '@/core'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { MaybeRef } from 'vue'

const { customerFiles } = serviceConfig

export type TUploadBody = {
  file: File
  customerId?: MaybeRef<number | string>
  modelId?: MaybeRef<number | string>
  categoryId?: MaybeRef<number | string>
  fileId?: MaybeRef<number | string>
}

type BaseServiceOverrideOptions = {
  handlers?: {
    onUploadingFileChange?: (payload: TUploadBody) => void
  }
}
export const useFileQuery = (
  options: BaseServiceOptions<Pagination> & BaseServiceOverrideOptions,
) => {
  const { currentPage, handlers, enableQuery, filters } = options

  const { errorHttpNotification } = useNotification()

  const loadRecords = useQuery<TCustomerFiles, AxiosError>(
    [customerFiles, currentPage, filters?.loadRecords],
    () =>
      customerFilesHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
      onSuccess: (data) => {
        if (data.meta) {
          handlers?.onLoadRecords?.(data.meta ?? defaultPagination)
        }
      },
    },
  )

  const uploadFile = useMutation<void, AxiosError, TUploadBody>(
    (payload: TUploadBody) => {
      const uploadForm = new FormData()
      uploadForm.append('file', payload.file)
      if (payload.categoryId != null) {
        uploadForm.append('category_id', payload.categoryId.toString())
        uploadForm.append('_method', 'PUT')
      }
      return uploadServiceHandlers.uploadDocument(
        {
          ...payload,
          modelId: payload.customerId,
          fileId: payload.fileId,
        },
        uploadForm,
      )
    },
    {
      onSuccess: (_, payload) => {
        handlers?.onUploadingFileChange?.(payload)
      },
      onError: (error, payload) => {
        handlers?.onUploadingFileChange?.(payload)

        return errorHttpNotification(error)
      },
    },
  )

  return {
    loadRecords,
    uploadFile,
  }
}
