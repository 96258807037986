import api from '@/core/config/axios'
import {
  createCatalogEndpoint,
  createCrudEndpoints,
  createNestedCrudEndpoints,
} from '@/core/utils'
import { MaybeRef, toValue } from 'vue'
import { TCustomer, TCustomers, TLot, TLots, TProjects, TSale, TSales } from '.'
import { TLeads } from '../leads'

type ServiceParams = {
  modelId?: MaybeRef<number | string>
  categoryId?: MaybeRef<number | string>
}

export const serviceConfig = {
  model: 'sales',
  version: 'v1',
  projects: {
    model: 'projects',
    version: 'v1',
  },
  lots: {
    model: 'lots',
    version: 'v1',
  },
  customers: {
    model: 'customers',
    version: 'v1',
  },
  leads: {
    model: 'leads',
    version: 'v1',
  },
}

const { projects, lots, customers, leads } = serviceConfig

const moduleServiceHandlers = createCrudEndpoints<TSales, TSale>(
  serviceConfig.model,
  serviceConfig.version,
)

const projectsCatalogService = createCatalogEndpoint<TProjects>(
  projects.model,
  projects.version,
)

const lotsServiceHandler = createCrudEndpoints<TLots, TLot>(
  lots.model,
  lots.version,
)

const customersServiceHandlers = createCrudEndpoints<TCustomers, TCustomer>(
  customers.model,
  customers.version,
)

const customerSalesHandlers = createNestedCrudEndpoints<TCustomers, TCustomer>(
  serviceConfig.model,
  customers.model,
  customers.version,
)

const leadServiceHandlers = createCatalogEndpoint<TLeads>(
  leads.model,
  leads.version,
)

const uploadServiceHandlers = {
  loadFilesByCustomer: async (params: ServiceParams): Promise<TCustomer[]> =>
    api
      .get(
        `${serviceConfig.version}/${serviceConfig.model}/${toValue(
          params.modelId,
        )}/files-to-upload`,
      )
      .then((response) => response.data),

  uploadDocument: async (
    params: ServiceParams,
    payload: FormData,
  ): Promise<void> =>
    api
      .post(`v1/customers/${toValue(params.modelId)}/files/`, payload)
      .then((response) => response.data),
}

export {
  customerSalesHandlers,
  customersServiceHandlers,
  leadServiceHandlers,
  lotsServiceHandler,
  moduleServiceHandlers,
  projectsCatalogService,
  uploadServiceHandlers,
}
