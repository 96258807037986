import { ToQueryStringFilters, useToQueryString } from '@/core'
import { TRecordAction } from '@/core/types'
import { TCustomer } from '@/modules/customers'
import { createInjectionState, toReactive } from '@vueuse/core'
import { computed, ref, toValue } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import type { TSale } from '../../../types'
import { useCatalogQuery } from '../query/useCatalogQuery'
import { useModuleQuery } from '../query/useModuleQuery'
import { TUploadBody, useUploadQuery } from '../query/useUploadQuery'

const [useProvideSale, useSaleModule] = createInjectionState(() => {
  const { t } = useI18n()

  const form = ref<TSale>({})
  const customerForm = ref<TCustomer>({})
  const router = useRouter()
  const route = useRoute()

  const queueFilesUploading = ref<TUploadBody[]>([])

  const saleSteps = ref([
    { label: t('sales.SELECT_PROJECT') },
    { label: t('sales.CREATE_CUSTOMER') },
    { label: t('sales.DOCUMENTATION') },
  ])

  const lotsFilters = ref<ToQueryStringFilters>({
    project_id: [],
    sold: false,
  })

  const lotsQueryParams = useToQueryString(toReactive(lotsFilters))

  const isEdit = computed(() => route.name === 'salesAssignProjectEdit')

  const isDetail = computed(() => route.name === 'saleDetail')

  const catalogQuery = useCatalogQuery({
    filters: {
      lotsCatalog: lotsQueryParams.queryParams,
    },
    enableQuery: {
      lotsCatalog: computed(() => lotsQueryParams.params.value.length > 1),
      leadsCatalog: true,
    },
  })

  const uploadQuery = useUploadQuery({
    modelId: computed(() => route.params?.id?.toString()),
    enableQuery: {
      loadFilesByCustomer:
        computed(() => !!route.params?.id) &&
        (route.name === 'submitCustomerDocuments' ||
          route.name === 'saleDetail'),
    },
    handlers: {
      onUploadingFileSuccess: (payload) => {
        onRemoveQueue(payload)
      },
      onUploadingFileError: (payload) => {
        onRemoveQueue(payload)
      },
    },
  })

  const moduleQuery = useModuleQuery({
    modelId: computed(() => route.params?.id?.toString()),
    enableQuery: {
      loadRecords: false,
      loadRecord: isEdit.value || isDetail.value,
    },
  })

  const onAdd = async (payload: TSale) => {
    await moduleQuery.addRecord.mutateAsync(payload).then((sale) => {
      onRedirect(sale)
    })
  }

  const onUploadFile = async (payload: TUploadBody) => {
    onQueueUploading(payload)
    await uploadQuery.uploadFile.mutateAsync(payload)
    await uploadQuery.loadFilesByCustomer.refetch({
      queryKey: [new Date()],
    })
  }

  const onRedirect = (payload: TSale) => {
    router.replace({ name: 'salesAssignCustomer', params: { id: payload.id } })
  }

  const onQueueUploading = (payload: TUploadBody) => {
    queueFilesUploading.value.push({
      file: payload.file,
      categoryId: toValue(payload.categoryId),
      customerId: toValue(payload.customerId),
    })
  }

  const onRemoveQueue = (payload: TUploadBody) => {
    queueFilesUploading.value = queueFilesUploading.value.filter(
      (queue) =>
        queue.categoryId !== payload.categoryId &&
        queue.customerId !== payload.customerId,
    )
  }

  const isUploading = (customerId?: number, categoryId?: number) => {
    return queueFilesUploading.value.find(
      (queue) =>
        queue.categoryId === categoryId && queue.customerId === customerId,
    )
  }

  const onEdit = async (payload: TSale) => {
    await moduleQuery.editRecord
      .mutateAsync(payload)
      .then((sale) => onRedirect(sale))
  }

  const onAction = async (action: TRecordAction) => {
    await moduleQuery.actionRecord.mutateAsync(action)
    isDetail.value
      ? router.push({ name: 'sales' })
      : router.push({ name: 'saleDetail', params: { id: route.params.id } })
  }

  const isLoading = computed(
    () =>
      moduleQuery.loadRecord.isLoading.value ||
      catalogQuery.projectsCatalog.isLoading.value,
  )
  const model = computed(() => moduleQuery.loadRecord.data.value)
  const projectsCatalog = computed(
    () => catalogQuery.projectsCatalog.data.value,
  )

  const leadsCatalog = computed(() => catalogQuery.leadsCatalog.data.value)

  const lotsCatalog = computed(() => catalogQuery.lotsCatalog.data.value?.data)

  const fileList = computed(() => uploadQuery.loadFilesByCustomer.data.value)

  return {
    saleSteps,
    form,
    isLoading,
    isUploading,
    model,
    projectsCatalog,
    lotsCatalog,
    leadsCatalog,
    isEdit,
    lotsFilters,
    fileList,
    queueFilesUploading,
    customerForm,
    onUploadFile,
    onEdit,
    onAdd,
    onAction,
  }
})

export { useProvideSale, useSaleModule }
