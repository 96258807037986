import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import type {
  BaseServiceOptions,
  Pagination,
  TRecordAction,
} from '@/core/types'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import {
  quotationsServiceHandlers,
  serviceConfig,
  TQuotations,
  TQuote,
  quotesActionsService,
} from '../../..'
import { toValue } from 'vue'

const { model } = serviceConfig.quotations

export const useQuoteQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, enableQuery, filters, modelId, handlers } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TQuote>({
    model,
    currentPage: toValue(currentPage) ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const addRecord = useMutation<TQuote, AxiosError, TQuote>(
    (payload: TQuote) =>
      quotationsServiceHandlers.addRecord(payload, { modelId }),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const editRecord = useMutation<TQuote, AxiosError, TQuote>(
    (payload: TQuote) =>
      quotationsServiceHandlers.editRecord(payload, {
        modelId,
        modelChildId: payload.id,
      }),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const deleteRecord = useMutation<void, AxiosError, TQuote>(
    (payload) =>
      quotationsServiceHandlers.deleteRecord({
        modelId,
        modelChildId: payload.id,
      }),
    {
      onSuccess: (_, quote) => serviceHandlers.deleteRecord(quote),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TQuote, AxiosError, TRecordAction>(
    (payload: TRecordAction) => quotesActionsService.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TQuotations, AxiosError>(
    [model, currentPage, filters?.loadRecords],
    () =>
      quotationsServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        modelId,
        page: currentPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
      onSuccess: (data) => {
        handlers?.onLoadRecords?.(data.meta ?? defaultPagination)
      },
    },
  )

  return {
    loadRecords,
    addRecord,
    editRecord,
    deleteRecord,
    actionRecord,
  }
}
