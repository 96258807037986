import { defaultPagination, useNotification, useServiceHandlers } from '@/core'
import type {
  BaseServiceOptions,
  Pagination,
  TRecordAction,
} from '@/core/types'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import {
  type TBusinessName,
  type TBusinessNames,
  moduleServiceHandlers,
  serviceConfig,
} from '../../..'

const { model } = serviceConfig

export const useModuleQuery = (options: BaseServiceOptions<Pagination>) => {
  const { currentPage, handlers, enableQuery, filters } = options

  const { errorHttpNotification } = useNotification()
  const serviceHandlers = useServiceHandlers<TBusinessName>({
    model,
    currentPage: currentPage ?? 1,
    filterParams: filters?.loadRecords ?? '',
  })

  const editRecord = useMutation<TBusinessName, AxiosError, TBusinessName>(
    (payload: TBusinessName) =>
      moduleServiceHandlers.editRecord(payload, payload.id),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const addRecord = useMutation<TBusinessName, AxiosError, TBusinessName>(
    (payload: TBusinessName) => moduleServiceHandlers.addRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.addRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const actionRecord = useMutation<TBusinessName, AxiosError, TRecordAction>(
    (payload: TRecordAction) => moduleServiceHandlers.actionRecord(payload),
    {
      onSuccess: (payload) => serviceHandlers.updateRecord(payload),
      onError: (error) => errorHttpNotification(error),
    },
  )

  const loadRecords = useQuery<TBusinessNames, AxiosError>(
    [model, currentPage, filters?.loadRecords],
    () =>
      moduleServiceHandlers.loadRecords({
        dynamicFilters: filters?.loadRecords,
        page: currentPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery?.loadRecords,
      onError: (error) => errorHttpNotification(error),
      onSuccess: (data) => {
        if (data.meta) {
          handlers?.onLoadRecords?.(data.meta ?? defaultPagination)
        }
      },
    },
  )

  return {
    loadRecords,
    addRecord,
    editRecord,
    actionRecord,
  }
}
