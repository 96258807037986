import { advisorsModuleRoutes } from '@/modules/advisors'
import { businessNamesModuleRoutes } from '@/modules/business-names'
import { campaignsModuleRoutes } from '@/modules/campaigns'
import { coOwnersModuleRoutes } from '@/modules/co-owners'
import { customersModuleRoutes } from '@/modules/customers'
import { dashboardModuleRoutes } from '@/modules/dashboard'
import { leadsModuleRoutes } from '@/modules/leads'
import { projectsModuleRoutes } from '@/modules/projects'
import { referralsModuleRoutes } from '@/modules/referrals'
import { salesModuleRoutes } from '@/modules/sales'
import { socialNetworksModuleRoutes } from '@/modules/social-networks'
import { usersModuleRoutes } from '@/modules/users'
import { RouteRecordRaw } from 'vue-router'

const DashboardLayout = () =>
  import(
    /* webpackChunkName: "dashboard-layout" */ '@/core/layouts/DashboardLayout.vue'
  )

export const dashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: 'dashboard' },
    component: DashboardLayout,
    meta: {
      auth: true,
    },
    children: [
      ...dashboardModuleRoutes,
      ...usersModuleRoutes,
      ...advisorsModuleRoutes,
      ...campaignsModuleRoutes,
      ...leadsModuleRoutes,
      ...projectsModuleRoutes,
      ...businessNamesModuleRoutes,
      ...coOwnersModuleRoutes,
      ...salesModuleRoutes,
      ...customersModuleRoutes,
      ...referralsModuleRoutes,
      ...socialNetworksModuleRoutes,
    ],
  },
]
