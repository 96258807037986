import { authRoles } from '@/core'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN, ADVISOR } = authRoles

const Leads = () =>
  import(/* webpackChunkName: "leads-view" */ './views/pages/index.vue')

const LeadsDetail = () =>
  import(/* webpackChunkName: "leadsDetail-view" */ './views/pages/detail.vue')

const LeadComments = () =>
  import(
    /* webpackChunkName: "leadsDetail-view" */ './views/pages/comments.vue'
  )

export const leadsModuleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/leads',
    name: 'leads',
    component: Leads,
    meta: {
      title: 'Leads',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: '/leads/create',
    name: 'leadsCreate',
    component: LeadsDetail,
    meta: {
      title: 'Crear Leads',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: '/leads/:id/details',
    name: 'leadsDetails',
    component: LeadsDetail,
    meta: {
      title: 'Gestionar Leads',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
  {
    path: '/leads/:id/comments',
    name: 'leadComments',
    component: LeadComments,
    meta: {
      title: 'Gestionar Leads',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
]
