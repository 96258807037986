import { authRoles } from '@/core'
import { RouteRecordRaw } from 'vue-router'

const { ADMIN, ADVISOR } = authRoles

const CoOwners = () =>
  import(/* webpackChunkName: "login-view" */ './views/pages/index.vue')

export const coOwnersModuleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/co-owners',
    name: 'co-owners',
    component: CoOwners,
    meta: {
      title: 'Copropietarios',
      auth: {
        roles: [ADMIN, ADVISOR],
        redirect: '/auth/login',
        notFoundRedirect: { name: '/404' },
        forbiddenRedirect: '/404',
      },
    },
  },
]
