import { DataWithPagination, TBaseCampaign, TBaseMedia } from '@/core/types'
import {
  createCatalogEndpoint,
  createCrudEndpoints,
  createExportEndpoint,
} from '@/core/utils'
import { TCampaign, TCatalogs } from '.'
import { TProjects } from '../projects'
import { TReferrals, TSocialNetworks } from './types'

export type TCampaigns = DataWithPagination<TBaseCampaign>

export type TMedias = TBaseMedia[]

export const serviceConfig = {
  model: 'campaigns',
  version: 'v1',
  projects: {
    model: 'projects',
    version: 'v1',
  },
  referrals: {
    model: 'referrals',
    version: 'v1',
  },
  socialNetworks: {
    model: 'social-networks',
    version: 'v1',
  },
}

const { projects, referrals, socialNetworks } = serviceConfig

const moduleServiceHandlers = createCrudEndpoints<TCampaigns, TCampaign>(
  serviceConfig.model,
  serviceConfig.version,
)
const moduleExportHandler = createExportEndpoint(serviceConfig.model)

const categoriesCatalog = createCatalogEndpoint<TCatalogs>(
  '',
  serviceConfig.version,
)

const catalogServiceHandler = {
  categoriesCatalog,
}

const projectsCatalogService = createCatalogEndpoint<TProjects>(
  projects.model,
  projects.version,
)

const socialNetworksCatalogService = createCatalogEndpoint<TSocialNetworks>(
  socialNetworks.model,
  socialNetworks.version,
)

const referralsCatalogService = createCatalogEndpoint<TReferrals>(
  referrals.model,
  referrals.version,
)

export {
  catalogServiceHandler,
  moduleExportHandler,
  moduleServiceHandlers,
  projectsCatalogService,
  referralsCatalogService,
  socialNetworksCatalogService,
}
